import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useElapsedTime } from 'use-elapsed-time';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import { useAppContext } from '../../common/AppContext';
import Dots from '../../common/Dots';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import EducacionFinancieraGame from './EducacionFinancieraGame';
import ReactGA from 'react-ga4';

const EF_2 = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const game = appState.currentScreen.game as EducacionFinancieraGame;

  const [radioValue, setRadioValue] = useState('');

  let elapsedTime = useElapsedTime({
    isPlaying: true,
  });

  const amount = game.questionAmount();
  const current = game.questionIndex();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/educacion-financiera/2/' + current,
    });

    ReactGA.event({
      category: 'vista',
      action: 'render',
      label: '/educacion-financiera/2/' + current,
      nonInteraction: true,
    });
  }, [current]);

  ScreenUtils.showBG();

  function onChanged(event: ChangeEvent<HTMLInputElement>) {
    game.setAnswerHistorical(
      question.id,
      event.target.id,
      elapsedTime.elapsedTime
    );
    setRadioValue(event.target.id);
  }

  function next(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formItems = Array.from(event.target as HTMLFormElement);

    let seleccion: string = '';

    for (var j = 0; j < formItems.length; j++) {
      let item = formItems[j] as HTMLFormElement;

      if (item.checked) {
        seleccion = item.id;
        break;
      }
    }

    // TODO remover esto cuando este implementado el disabling del boton
    if (seleccion.length === 0) {
      seleccion = 'sin seleccion';
    }

    game.setAnswer(question.id, seleccion, elapsedTime.elapsedTime);

    elapsedTime.reset();
    setRadioValue('');
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  const question = game.getQuestion();

  interface IRadio {
    name: string;
    value: string;
  }

  let radios: IRadio[] = [];

  question.options.forEach(option =>
    radios.push({ name: option.text, value: option.id })
  );

  let counter = 0;
  function createKey() {
    return counter++;
  }

  return (
    <form onSubmit={next} key='formulariousuario' className='flexColumnLayout'>
      <input type='hidden' name='id' value={question.id} />
      <p></p>
      <p></p>
      <Dots amount={amount} active={current} key='dots' />
      <p className={'line1 textM efPregunta'}>{question.question}</p>

      <ul className='efOptions'>
        {radios.map((radio, index) => (
          <li key={createKey()}>
            <label key={createKey()}>
              <input
                name='option'
                type='radio'
                checked={radioValue === radio.value}
                id={radio.value}
                onChange={onChanged}
                key={createKey()}
              />
              {radio.name}
            </label>
          </li>
        ))}
      </ul>
      <p></p>
      <div className='bottomButtonsSingleRight'>
        <button className='nextButton' disabled={radioValue.length === 0}>
          Continuar
        </button>
      </div>
    </form>
  );
};

export default EF_2;
