import { useAppContext } from '../../common/AppContext';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import MemoriaTrabajoImagenesGame from './MemoriaTrabajoImagenesGame';
import ReactGA from 'react-ga4';

const MTI_8 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/memoria-trabajo-imagenes/8' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/memoria-trabajo-imagenes/8',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  let game = appState.currentScreen.game as MemoriaTrabajoImagenesGame;
  function next() {
    pageDispatch({ type: PageScreenReducerActionTypes.JUMP, payload: -2 });
  }

  function back() {
    game.tutorialRestarted2();
    pageDispatch({ type: PageScreenReducerActionTypes.RESET });
  }

  return (
    <>
      <p className='sideMargin50'>
        Si no comprendés el juego, podés regresar al tutorial
      </p>
      <div className='bottomButtonsDouble'>
        <button className='prevButton' onClick={back}>
          Regresar
        </button>
        <button className='nextButton' onClick={next}>
          Continuar
        </button>
      </div>
    </>
  );
};

export default MTI_8;
