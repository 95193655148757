import { ScreenUtils } from '../../../utils/ScreenUtils';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import logo from './../../../assets/images/intro_hola.png';
import ReactGA from 'react-ga4';

function IN_0() {
  ReactGA.send({ hitType: 'pageview', page: '/intro/0' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/intro/0',
    nonInteraction: true,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();

  function next() {
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  ScreenUtils.hideBG();

  return (
    <>
      <div className='sideMargin50'>
        <p className='textStrong textL'>¡Hola!</p>
        <img src={logo} alt='logo' className='logoSolapa' />
        <p>¿Necesitás un préstamo?</p>
        <p>
          Te invitamos a participar de una entrevista digital. Responderás
          preguntas, jugarás, seleccionarás imágenes y algo más.
        </p>
      </div>
      <div className='bottomButtonsSingleCenter'>
        <button className='nextButton widerButton' onClick={next}>
          Ingresar
        </button>
      </div>
    </>
  );
}

export default IN_0;
