import { ScreenUtils } from '../../../utils/ScreenUtils';
import { useAppContext } from '../../common/AppContext';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';

import GratificacionRetrasadaGame from './GratificacionRetrasadaGame';
import ReactGA from 'react-ga4';

const GR_1 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/gratificacion-retrasada/1' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/gratificacion-retrasada/1',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const game = appState.currentScreen.game as GratificacionRetrasadaGame;

  const currentStage = game.questionStage();

  let titles: string[] = [];
  switch (currentStage) {
    case 0:
      titles = [
        'Un familiar te ofrece una gran cantidad de dinero como regalo. Pero te propone la siguiente situación:',
        'Podés recibir el regalo hoy o esperar una determinada cantidad de meses para recibir una suma mayor.',
        '¡Vamos a jugar!',
      ];
      break;
    case 1:
      titles = [
        '¡Ahora imaginá que sólo deberás esperar 3 MESES!',
        '¿Qué decidís?',
      ];
      break;
    case 2:
      titles = [
        '¡Ahora imaginá que deberás esperar 12 MESES!',
        '¿Qué decidís?',
      ];
      break;
  }

  ScreenUtils.hideBG();

  function next() {
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  return (
    <>
      <p></p>
      <div className='sideMargin50'>
        {titles.map((title, index) => {
          return <p key={index}>{title}</p>;
        })}
      </div>
      <div className='bottomButtonsSingleRight'>
        <button className='nextButton' onClick={next}>
          Continuar
        </button>
      </div>
    </>
  );
};

export default GR_1;
