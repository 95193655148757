import { useReducer } from 'react';
import { useAppContext } from '../../common/AppContext';
import Header from '../../common/Header';
import PageFlow from '../../common/PageFlow';
import PageScreenContext from '../../common/PageScreenContext';
import { pageScreenReducer } from '../../common/PageScreenReducer';
import MentirosoGame, { MentirosoVariants } from './MentirosoGame';

import M1_0 from './M1_0';
import M1_1 from './M1_1';
import M1_2 from './M1_2';
import M1_4 from './M1_4';
import M1_5 from './M1_5';
import M2_0 from './M2_0';
import M2_1 from './M2_1';
import M2_4 from './M2_4';
import M2_5 from './M2_5';
import M3_0 from './M3_0';
import M3_1 from './M3_1';
import M3_4 from './M3_4';
import M_3 from './M_3';

let screens: JSX.Element[] = [];

const Mentiroso = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const game = appState.currentScreen.game as MentirosoGame;
  game.setAsLastGame(appState.currentScreen.last);

  function prepareScreens() {
    screens = [];

    switch (game.gameVariant()) {
      case MentirosoVariants.Bronze:
        screens.push(<M1_0 />);
        screens.push(<M1_1 />);
        screens.push(<M1_2 />);
        break;
      case MentirosoVariants.Silver:
        screens.push(<M2_0 />);
        screens.push(<M2_1 />);
        break;
      case MentirosoVariants.Gold:
        screens.push(<M3_0 />);
        screens.push(<M3_1 />);
        break;
    }

    for (let i = 0; i < game.questionAmount(); i++) {
      screens.push(<M_3 />);
    }

    switch (game.gameVariant()) {
      case MentirosoVariants.Bronze:
        screens.push(<M1_4 />);
        screens.push(<M1_5 />);
        break;
      case MentirosoVariants.Silver:
        screens.push(<M2_4 />);
        screens.push(<M2_5 />);
        break;
      case MentirosoVariants.Gold:
        screens.push(<M3_4 />);
        break;
    }
  }

  prepareScreens();

  const initialState = { index: 0, length: screens.length };
  const [pageState, pageDispatch] = useReducer(pageScreenReducer, initialState);

  const providerState = {
    state: pageState,
    dispatch: pageDispatch,
  };

  return (
    <PageScreenContext.Provider value={providerState}>
      <Header>
        <PageFlow children={screens[pageState.index]} />
      </Header>
    </PageScreenContext.Provider>
  );
};

export default Mentiroso;
