import logo from './../../../assets/images/cierre.gif';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import ReactGA from 'react-ga4';

const MF_2 = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: '/cuestionario-manejo-financiero/2',
  });

  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/cuestionario-manejo-financiero/2',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();

  ScreenUtils.hideBG();

  function next() {
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  return (
    <>
      <img src={logo} alt='logo' className={'logoSolapa'} />
      <p className={'textStrong textM'}>¡Excelente!</p>

      <p className={'sideMargin50'}>
        A partir de ahora vamos a realizar una serie de actividades lúdicas con
        el fin de conocerte mejor.
      </p>

      <p>¡Vamos!</p>

      <div className='bottomButtonsSingleRight'>
        <button className='nextButton' onClick={next}>
          Continuar
        </button>
      </div>
    </>
  );
};

export default MF_2;
