import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import MemoriaTrabajoNumerosGame, {
  MTNStates,
} from './MemoriaTrabajoNumerosGame';
import { useAppContext } from '../../common/AppContext';
import ReactGA from 'react-ga4';

const MTN_1 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/memoria-trabajo-numeros/1' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/memoria-trabajo-numeros/1',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const game = appState.currentScreen.game as MemoriaTrabajoNumerosGame;

  ScreenUtils.showBG();

  function back() {
    game.tutorialRestart();
    game.resetData();
    pageDispatch({ type: PageScreenReducerActionTypes.RESET });
  }

  function next() {
    switch (game.getState()) {
      case MTNStates.TEST:
        pageDispatch({ type: PageScreenReducerActionTypes.SET, payload: 4 });
        break;
      case MTNStates.TUTORIAL_FAILED:
        game.skipTutorial();
        pageDispatch({ type: PageScreenReducerActionTypes.SET, payload: 4 });
        break;
      default:
        pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
        break;
    }
  }

  let titulo: JSX.Element = <></>;
  let buttons: JSX.Element = (
    <div className='bottomButtonsSingleRight'>
      <button className='nextButton' onClick={next}>
        Continuar
      </button>
    </div>
  );

  switch (game.getState()) {
    case MTNStates.INSTRUCTIONS:
      titulo = <p>Avancemos con un ejemplo.</p>;
      break;
    case MTNStates.TUTORIAL:
      titulo = (
        <>
          <p>Empecemos con una prueba sencilla.</p>
          <p>¿Vamos?</p>
        </>
      );
      break;
    case MTNStates.TEST:
      titulo = (
        <>
          <p>Comienza el juego de los números.</p>
          <p>¡Vamos!</p>
        </>
      );
      break;
    case MTNStates.TUTORIAL_FAILED:
      titulo = (
        <>
          <p>
            Si no comprendés el juego, podés regresar al tutorial y comenzar de
            nuevo
          </p>
        </>
      );
      buttons = (
        <div className='bottomButtonsDouble'>
          <button className='prevButton' onClick={back}>
            Regresar
          </button>
          <button className='nextButton' onClick={next}>
            Continuar
          </button>
        </div>
      );
      break;
  }

  return (
    <>
      <div className='sideMargin50'>{titulo}</div>
      {buttons}
    </>
  );
};

export default MTN_1;
