import {
  AppAction,
  AppReducerActionTypes,
} from '../components/common/AppReducer';

export function ShowModal(
  conection: boolean,
  appDispatch: React.Dispatch<AppAction>,
  code: string,
  message: string
) {
  let modalMessage = conection
    ? 'Parece que se ha perdido la conexión a internet. Verificalo y continuá la entrevista'
    : 'Error de la applicación, por favor reintente en unos minutos';
  appDispatch({
    type: AppReducerActionTypes.SHOW_MODAL,
    modalMessage: modalMessage,
    modalExtra: { code: code, message: message },
  });
}
