import { useAppContext } from '../../common/AppContext';
import { AppReducerActionTypes } from '../../common/AppReducer';
import { SaveResults } from '../../common/SaveResults';
import MemoriaTrabajoImagenesGame from './MemoriaTrabajoImagenesGame';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import logo from './../../../assets/images/cierre.gif';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import { ShowModal } from '../../../utils/ShowModal';
import { useState } from 'react';
import ReactGA from 'react-ga4';

const MTI_9 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/memoria-trabajo-imagenes/9' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/memoria-trabajo-imagenes/9',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const [disableContinuar, setDisableContinuar] = useState(false);

  const game = appState.currentScreen.game as MemoriaTrabajoImagenesGame;

  ScreenUtils.hideBG();

  let congratulations = '';

  let score = game.getScore();

  if (score < 25) {
    congratulations = '¡Buen trabajo!';
  } else if (score < 50) {
    congratulations = '¡Bien hecho!';
  } else if (score < 75) {
    congratulations = '¡Muy buen trabajo!';
  } else {
    congratulations = '¡Excelente!';
  }

  function next() {
    setDisableContinuar(true);
    SaveResults(
      game.collectedData(),
      appState.bearer_token,
      () => {
        setDisableContinuar(false);
        pageDispatch({ type: PageScreenReducerActionTypes.RESET });
        appDispatch({ type: AppReducerActionTypes.NEXT });
      },
      (connectionError: boolean, code: string, message: string) => {
        ShowModal(connectionError, appDispatch, code, message);
        setDisableContinuar(false);
      }
    );
  }

  return (
    <>
      <div>
        <img src={logo} alt='logo' className='logoSolapa' />
        <p className={'textStrong textL'}>{congratulations}</p>
      </div>
      <p></p>
      <div className='bottomButtonsSingleRight'>
        <button
          className='nextButton'
          onClick={next}
          disabled={disableContinuar}
        >
          Continuar
        </button>
      </div>
    </>
  );
};

export default MTI_9;
