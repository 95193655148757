import cssClasses from './Dots.module.css';

interface DotsProps {
  amount: number;
  active: number;
  dash?: boolean;
}

const Dots = (
  props: DotsProps = {
    amount: 1,
    active: 0,
    dash: false,
  }
) => {
  let dots = [];

  let css: string = props.dash ? cssClasses.dash : cssClasses.dot;
  let cssActive: string = props.dash
    ? cssClasses.activeDash
    : cssClasses.activeDot;
  let cssClass: string;

  for (let i: number = 0; i < props.amount; i++) {
    cssClass = props.dash
      ? props.active >= i + 1
        ? cssActive
        : css
      : props.active === i
      ? cssActive
      : css;
    dots.push(<span key={i} className={cssClass} />);
  }
  return <div>{dots.map(dot => dot)}</div>;
};

export default Dots;
