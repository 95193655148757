import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import Figuras from './Figuras';
import ReactGA from 'react-ga4';

const MTI_3 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/memoria-trabajo-imagenes/3' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/memoria-trabajo-imagenes/3',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();

  function next() {
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  const figuras = Figuras();

  return (
    <>
      <div>
        <p className='sideMargin50'>
          Luego, aparecerán cuatro opciones de las cuales sólo una representa el
          orden correcto de las figuras. <br />
          En este ejemplo, la opción correcta está resaltada.
        </p>
        <div className='gridLayout flexCenter'>
          <button className={'buttonBG buttonShadow flexRowLayoutSA'}>
            <img src={figuras[8]} alt='' className='mtiRespuesta' />
            <img src={figuras[6]} alt='' className='mtiRespuesta' />
            <img src={figuras[7]} alt='' className='mtiRespuesta' />
          </button>

          <button className={'buttonBG buttonShadow flexRowLayoutSA'}>
            <img src={figuras[8]} alt='' className='mtiRespuesta' />
            <img src={figuras[7]} alt='' className='mtiRespuesta' />
            <img src={figuras[6]} alt='' className='mtiRespuesta' />
          </button>

          <button className={'buttonBG buttonShadow flexRowLayoutSA'}>
            <img src={figuras[6]} alt='' className='mtiRespuesta' />
            <img src={figuras[8]} alt='' className='mtiRespuesta' />
            <img src={figuras[7]} alt='' className='mtiRespuesta' />
          </button>

          <button
            className={
              'buttonBG buttonShadowSelected flexRowLayoutSA highlighted'
            }
          >
            <img src={figuras[6]} alt='' className='mtiRespuesta' />
            <img src={figuras[7]} alt='' className='mtiRespuesta' />
            <img src={figuras[8]} alt='' className='mtiRespuesta' />
          </button>
        </div>
      </div>
      <div className='bottomButtonsSingleRight'>
        <button className='nextButton' onClick={next}>
          Continuar
        </button>
      </div>
    </>
  );
};

export default MTI_3;
