import React, { useEffect } from 'react';

interface ISliderProps {
  id: string;
  texts: string[];
  onChange: (id: string, value: string) => void;
  onRelease?: (id: string, value: string) => void;
  initialValue: (id: string, value: string) => void;
  steps?: number;
  continuous?: boolean;
  readOnly?: boolean;
}

const Slider = (props: ISliderProps) => {
  let min = 1;
  let max = props.texts.length;

  if (props.steps) {
    max = props.steps;
  }

  let defaultValue = Math.round(max / 2);

  useEffect(() => {
    props.initialValue(props.id, defaultValue.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function sliderChange(event: React.ChangeEvent<HTMLInputElement>) {
    props.onChange(props.id, event.target.value);
  }

  function sliderReleaseMouse(event: any) {
    if (props.onRelease) {
      props.onRelease(props.id, event.currentTarget.value);
    }
  }

  function sliderReleaseTouch(event: any) {
    if (props.onRelease) {
      props.onRelease(props.id, event.currentTarget.value);
    }
  }

  function sliderReleaseKeyboard(event: any) {
    if (props.onRelease) {
      props.onRelease(props.id, event.currentTarget.value);
    }
  }

  let sliderDisplayable = (
    <input
      key={props.id}
      type='range'
      min={min}
      max={max}
      step={props.continuous ? 'any' : 1}
      defaultValue={defaultValue}
      onChange={sliderChange}
      className={'slider'}
      onMouseUp={sliderReleaseMouse}
      onTouchEnd={sliderReleaseTouch}
      onKeyUp={sliderReleaseKeyboard}
    />
  );

  if (props.readOnly) {
    sliderDisplayable = (
      <div className='fakeSlider flexColumnLayout flexSA'>
        <div className='fakeSliderTrack'>
          <div className='fakeSliderThumb'></div>
        </div>
      </div>
    );
  }

  function filterTexts(text: string) {
    text = text.replace('<B>', '<span class="textStrong sliderFiltered">');
    text = text.replace('</B>', '</span>');
    return { __html: text };
  }

  let linesContainer: JSX.Element = <></>;

  if (!props.continuous) {
    let lines = [];

    for (let i = 0; i < max; i++) {
      lines.push(
        <div key={props.id + '-line-' + i} className={'sliderLine'} />
      );
    }

    linesContainer = (
      <div className={'flexRowLayout sliderLines'}>
        {lines.map((line, index) => {
          return line;
        })}
      </div>
    );
  }

  return (
    <div className='sliderComponent'>
      {sliderDisplayable}
      {linesContainer}
      <div className={'flexRowLayout sliderTextLayout'}>
        {props.texts.map((text, index) => {
          return (
            <div
              key={props.id + '-text-' + index}
              className={'textXS textThin sliderText'}
              dangerouslySetInnerHTML={filterTexts(text)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Slider;
