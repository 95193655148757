import { IGame } from '../../../interfaces/IGame';
import { Rand } from '../../../utils/Rand';
import { Sequences } from '../../../utils/Sequences';
import { IGameResults } from '../../../interfaces/IGameResults';

export enum MTIStates {
  INSTRUCTIONS,
  FINISHED,
  TUTORIAL_FAILED,
}

interface IQuestion {
  question: number[];
  answers: number[][];
  questionTimeout: number;
  answerTimeout: number;
  index: number;
}

interface IStage {
  questionsAmount: number;
  itemTypesAmount: number;
  questionItems: number;
  questionTimeout: number;
  answerAmount: number;
  answerTimeout: number;
  training: boolean;
  repeats: number;
}

interface IMTISettings {
  stages: IStage[];
  tutorial: boolean;
  trainingRetries: number;
  testRetries: number;
}

interface IUserAnswer {
  id: string;
  correct: boolean;
  timedOut: boolean;
  elapsedTime: number;
  index: number;
  question: number[];
  answers: number[][];
}

interface IUserSlider {
  value: string;
  elapsedTime: number;
}

interface IMTIResults extends IGameResults {
  answers: IUserAnswer[];
  performance: {
    [key: string]: { selected: IUserSlider; historical: IUserSlider[] };
  };
  tutorialRestarts: number;
  tutorialRestarts2: number;
  trainingMistakes: number;
  testMistakes: number;
}

class MemoriaTrabajoImagenesGame implements IGame {
  private settings: IMTISettings;
  private questions: IQuestion[][];
  private data: IMTIResults;
  private currentQuestion: number;
  private currentStage: number;
  private state: MTIStates;

  private correctAnswers: number;

  constructor(gameId: string, settings: IMTISettings) {
    this.settings = settings;
    this.questions = [];
    this.data = {
      gameId: gameId,
      answers: [],
      performance: {},
      tutorialRestarts: 0,
      tutorialRestarts2: 0,
      trainingMistakes: 0,
      testMistakes: 0,
    };
    this.currentQuestion = 0;
    this.currentStage = 0;
    let stages = this.settings.stages;
    this.state = MTIStates.INSTRUCTIONS;

    this.correctAnswers = 0;

    for (let j = 0; j < stages.length; j++) {
      this.questions[j] = [];
      for (let i = 0; i < stages[j].questionsAmount; i++) {
        let question = this.generateQuestion(stages[j]);
        this.questions[j].push(question);
      }
    }
  }

  public resetData(): void {
    this.questions = [];
    this.data.answers = [];
    this.data.performance = {};

    this.currentQuestion = 0;
    this.currentStage = 0;

    let stages = this.settings.stages;
    this.state = MTIStates.INSTRUCTIONS;

    for (let j = 0; j < stages.length; j++) {
      this.questions[j] = [];
      for (let i = 0; i < stages[j].questionsAmount; i++) {
        let question = this.generateQuestion(stages[j]);
        this.questions[j].push(question);
      }
    }

    console.log(this.questions);
  }

  private generateQuestion(stage: IStage): IQuestion {
    let sequence = Sequences.generate(stage.itemTypesAmount);
    let [question, remainder] = Sequences.sampleAndRemainder(
      sequence,
      stage.questionItems
    );
    let emergencyExitCounter = 100;
    let answers = [];

    while (
      answers.length < stage.answerAmount - 1 &&
      emergencyExitCounter > 0
    ) {
      emergencyExitCounter--;

      let repeats = Sequences.sample(question, stage.repeats);
      let rest = Sequences.sample(
        remainder,
        stage.questionItems - stage.repeats
      );
      let answer = Sequences.sample(repeats.concat(rest), stage.questionItems);

      if (!Sequences.compare(answer, question)) {
        answers.push(answer);
      }
    }

    let index = Math.trunc(Rand.random() * (answers.length + 1));
    answers.splice(index, 0, question);

    return {
      answers: answers,
      question: question,
      questionTimeout: stage.questionTimeout,
      answerTimeout: stage.answerTimeout,
      index: index,
    };
  }

  public collectedData(): IGameResults {
    this.data.score = this.getScore();
    this.data.results = JSON.stringify({
      correctas: this.correctAnswers,
      reintentos: this.data.testMistakes,
    });
    return this.data;
  }

  public tutorialRestarted() {
    this.data.tutorialRestarts++;
  }

  public tutorialRestarted2() {
    this.data.tutorialRestarts2++;
  }

  public questionAmount(): number {
    return this.questions[this.currentStage].length;
  }

  public getQuestion() {
    return this.questions[this.currentStage][this.currentQuestion];
  }

  public questionIndex(): number {
    return this.currentQuestion;
  }

  public questionStage(): number {
    return this.currentStage;
  }

  public getState() {
    return this.state;
  }

  public setAnswer(response: number, elapsedTime: number): boolean {
    let answer: IUserAnswer = {
      id: this.currentQuestion.toString(),
      correct:
        response ===
        this.questions[this.currentStage][this.currentQuestion].index,
      timedOut: response < 0,
      elapsedTime: elapsedTime,
      index: response,
      question:
        this.questions[this.currentStage][this.currentQuestion].question,
      answers: this.questions[this.currentStage][this.currentQuestion].answers,
    };
    this.data.answers.push(answer);

    let shouldFinish: boolean = false;
    let stage = this.settings.stages[this.currentStage];

    this.currentQuestion++;
    if (this.currentQuestion >= stage.questionsAmount) {
      this.currentQuestion = 0;
      this.currentStage++;
      if (this.currentStage >= this.settings.stages.length) {
        shouldFinish = true;
        this.state = MTIStates.FINISHED;
      }
    }

    if (!answer.correct) {
      if (stage.training) {
        this.data.trainingMistakes++;
        if (this.data.trainingMistakes % this.settings.trainingRetries === 0) {
          if (this.data.trainingMistakes > this.settings.trainingRetries) {
            this.currentStage++;
          } else {
            shouldFinish = true;
            this.state = MTIStates.TUTORIAL_FAILED;
          }
        }
      } else {
        this.data.testMistakes++;
        if (this.data.testMistakes >= this.settings.testRetries) {
          shouldFinish = true;
          this.state = MTIStates.FINISHED;
        }
      }
    } else {
      if (!stage.training) {
        this.correctAnswers++;
      }
    }

    return shouldFinish;
  }

  public setPerformance(id: string, response: string, elapsedTime: number) {
    let slider: IUserSlider = {
      value: response,
      elapsedTime: elapsedTime,
    };

    if (this.data.performance[id] === undefined) {
      this.data.performance[id] = {
        selected: slider,
        historical: [],
      };
    } else {
      this.data.performance[id].selected = slider;
    }
  }

  public setPerformanceHistorical(
    id: string,
    response: string,
    elapsedTime: number
  ) {
    let slider: IUserSlider = {
      value: response,
      elapsedTime: elapsedTime,
    };

    if (this.data.performance[id] === undefined) {
      this.data.performance[id] = {
        selected: { value: '', elapsedTime: 0 },
        historical: [],
      };
    }

    this.data.performance[id].historical.push(slider);
  }

  public getScore(): number {
    let score: number =
      this.correctAnswers -
      this.data.testMistakes / (this.settings.testRetries + 1);

    let totalQuestions: number = 0;

    for (let i = 0; i < this.settings.stages.length; i++) {
      if (this.settings.stages[i].training) {
        continue;
      }

      totalQuestions += this.settings.stages[i].questionsAmount;
    }

    let corr_min = this.settings.testRetries / (this.settings.testRetries + 1);

    return (100.0 * (score + corr_min)) / (totalQuestions + corr_min);
  }

  public hasTutorial(): boolean {
    return this.settings.tutorial;
  }

  public setAsLastGame(last: boolean) {
    this.data.end = last;
  }
}

export default MemoriaTrabajoImagenesGame;
