import { useReducer } from 'react';
import { useAppContext } from '../../common/AppContext';
// import { useAppContext } from '../../common/AppContext';
import Header from '../../common/Header';
import PageFlow from '../../common/PageFlow';
import PageScreenContext from '../../common/PageScreenContext';
import { pageScreenReducer } from '../../common/PageScreenReducer';
import CuestionarioManejoFinancieroContent from './CuestionarioManejoFinancieroContent';
import MF_0 from './MF_0';
import MF_1 from './MF_1';
import MF_2 from './MF_2';
import MF_3 from './MF_3';

let screens: JSX.Element[] = [];

function CuestionarioManejoFinanciero() {
  function prepareScreens(skipIntro: boolean) {
    screens = [];

    if (!skipIntro) {
      screens.push(<MF_0 />);
    }
    screens.push(<MF_1 />);
    screens.push(<MF_2 />);
    screens.push(<MF_3 />);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const game = appState.currentScreen
    .game as CuestionarioManejoFinancieroContent;

  prepareScreens(game.getScreenIndex() > 0);

  const initialState = { index: 0, length: screens.length };
  const [pageState, pageDispatch] = useReducer(pageScreenReducer, initialState);

  const providerState = {
    state: pageState,
    dispatch: pageDispatch,
  };

  return (
    <Header>
      <PageScreenContext.Provider value={providerState}>
        <PageFlow children={screens[pageState.index]} />
      </PageScreenContext.Provider>
    </Header>
  );
}

export default CuestionarioManejoFinanciero;
