import { IGame } from '../../../interfaces/IGame';
import { IGameResults } from '../../../interfaces/IGameResults';

interface IAnswer {
  value: string;
  elapsedTime: number;
}

interface IEFResults extends IGameResults {
  answers: {
    [key: string]: { selected: IAnswer; historical: IAnswer[] };
  };
  performance: {
    [key: string]: { selected: IAnswer; historical: IAnswer[] };
  };
}

interface IOptions {
  id: string;
  text: string;
}

export interface IQuestions {
  id: string;
  question: string;
  options: IOptions[];
}

interface IEFSettings {
  questions: IQuestions[];
}

class EducacionFinancieraGame implements IGame {
  private data: IEFResults;
  private settings: IEFSettings;
  private currentQuestion: number;

  constructor(gameId: string, settings: IEFSettings) {
    this.settings = settings;
    this.data = {
      gameId: gameId,
      answers: {},
      performance: {},
    };
    this.currentQuestion = 0;
  }

  public collectedData(): IGameResults {
    return this.data;
  }

  public resetData(): void {
    this.data.answers = {};
    this.currentQuestion = 0;
    this.data.performance = {};
  }

  public getQuestion(): IQuestions {
    return this.settings.questions[this.currentQuestion];
  }

  public questionAmount(): number {
    return this.settings.questions.length;
  }

  public questionIndex(): number {
    return this.currentQuestion;
  }

  public setAnswer(id: string, response: string, elapsedTime: number) {
    let answer: IAnswer = {
      value: response,
      elapsedTime: elapsedTime,
    };

    if (this.data.answers[id] === undefined) {
      this.data.answers[id] = {
        selected: answer,
        historical: [],
      };
    } else {
      this.data.answers[id].selected = answer;
    }

    this.currentQuestion++;
  }

  public setAnswerHistorical(
    id: string,
    response: string,
    elapsedTime: number
  ) {
    let answer: IAnswer = {
      value: response,
      elapsedTime: elapsedTime,
    };

    if (this.data.answers[id] === undefined) {
      this.data.answers[id] = {
        selected: { value: '', elapsedTime: 0 },
        historical: [],
      };
    }

    this.data.answers[id].historical.push(answer);
  }

  public setPerformance(id: string, response: string, elapsedTime: number) {
    let answer: IAnswer = {
      value: response,
      elapsedTime: elapsedTime,
    };

    if (this.data.performance[id] === undefined) {
      this.data.performance[id] = {
        selected: answer,
        historical: [],
      };
    } else {
      this.data.performance[id].selected = answer;
    }
  }

  public setPerformanceHistorical(
    id: string,
    response: string,
    elapsedTime: number
  ) {
    let answer: IAnswer = {
      value: response,
      elapsedTime: elapsedTime,
    };

    if (this.data.performance[id] === undefined) {
      this.data.performance[id] = {
        selected: { value: '', elapsedTime: 0 },
        historical: [],
      };
    }

    this.data.performance[id].historical.push(answer);
  }

  public setAsLastGame(last: boolean) {
    this.data.end = last;
  }
}

export default EducacionFinancieraGame;
