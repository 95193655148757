import { useAppContext } from '../../common/AppContext';
import { SaveResults } from '../../common/SaveResults';
import DespedidaContent from './DespedidaContent';

import emoticon1 from './../../../assets/images/despedidaencuesta1.png';
import emoticon2 from './../../../assets/images/despedidaencuesta2.png';
import emoticon3 from './../../../assets/images/despedidaencuesta3.png';
import emoticon4 from './../../../assets/images/despedidaencuesta4.png';
import emoticon5 from './../../../assets/images/despedidaencuesta5.png';
import emoticon6 from './../../../assets/images/despedidaencuesta6.png';
import emoticon7 from './../../../assets/images/despedidaencuesta7.png';
import emoticon8 from './../../../assets/images/despedidaencuesta8.png';

import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import { ChangeEvent, useEffect, useState } from 'react';
import { useElapsedTime } from 'use-elapsed-time';
import { ShowModal } from '../../../utils/ShowModal';
import ReactGA from 'react-ga4';

const D_1 = () => {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/despedida/1' });
    ReactGA.event({
      category: 'vista',
      action: 'render',
      label: '/despedida/1',
      nonInteraction: true,
    });
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const [disableContinuar, setDisableContinuar] = useState(false);

  const game = appState.currentScreen.game as DespedidaContent;

  const [radioValue, setRadioValue] = useState('');

  let elapsedTime = useElapsedTime({
    isPlaying: true,
  });

  function onChanged(event: ChangeEvent<HTMLInputElement>) {
    // console.log('historical: ' + question.id + ' => ' + event.target.value);
    game.setAnswerHistorical(event.target.value, elapsedTime.elapsedTime);
    setRadioValue(event.target.value);
  }

  function next() {
    let emoticon = document.querySelectorAll(
      "input[name='emoticon']:checked"
    )[0] as HTMLFormElement;

    let response = 'sin respuesta';
    if (emoticon) {
      response = emoticon.value;
    }
    game.setAnswer(response, elapsedTime.elapsedTime);
    setDisableContinuar(true);
    SaveResults(
      game.collectedData(),
      appState.bearer_token,
      () => {
        setDisableContinuar(false);
        pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
      },
      (connectionError: boolean, code: string, message: string) => {
        ShowModal(connectionError, appDispatch, code, message);
        setDisableContinuar(false);
      }
    );
  }

  const emoticons = [
    { image: emoticon1, text: 'Alegre' },
    { image: emoticon2, text: 'Aburrido/a' },
    { image: emoticon3, text: 'Abrumado/a' },
    { image: emoticon4, text: 'Enojado/a' },
    { image: emoticon5, text: 'Triste' },
    { image: emoticon6, text: 'Sorprendido/a' },
    { image: emoticon7, text: 'Asustado/a' },
    { image: emoticon8, text: 'Nervioso/a' },
  ];

  let counter = 0;
  function createKey() {
    return counter++;
  }

  return (
    <>
      <p></p>
      <p></p>
      <p className={'sideMargin50'}>
        Antes de despedirnos, queremos saber cómo te sentís en este momento.
      </p>
      <p className={'sideMargin50'}>Elegí la opción que mejor te represente:</p>
      <div className='gridLayout grid4 textXS'>
        {emoticons.map((emoticon, index) => (
          <label className='flexColumnLayout' key={createKey()}>
            <input
              key={index}
              id={`radio-${index}`}
              type='radio'
              name='emoticon'
              value={emoticon.text}
              checked={radioValue === emoticon.text}
              onChange={onChanged}
              data-radio='image'
            />
            <div data-radio-type='despedida' className='flexColumnLayout'>
              <img src={emoticon.image} alt={emoticon.text} />
              {emoticon.text}
            </div>
          </label>
        ))}
      </div>

      <div className='bottomButtonsSingleRight'>
        <button
          className='nextButton'
          onClick={next}
          disabled={radioValue.length === 0 || disableContinuar}
        >
          Finalizar
        </button>
      </div>
    </>
  );
};

export default D_1;
