import { IGame } from '../../../interfaces/IGame';
import { IGameResults } from '../../../interfaces/IGameResults';
import { Rand } from '../../../utils/Rand';

interface IAnswer {
  value: string;
  elapsedTime: number;
}

interface IRIResults extends IGameResults {
  answers: {
    [key: string]: { selected: IAnswer; historical: IAnswer[] };
  };
}

interface IOptions {
  id: string;
  text: string;
}

export interface IQuestions {
  id: string;
  situation: number;
  reverse: boolean;
}

interface IRISettings {
  questions: IQuestions[];
  options: IOptions[];
}

interface IRIResultados {
  situacion: number;
  valor: number;
}

class ResponsabilidadImagenesGame implements IGame {
  private data: IRIResults;
  private settings: IRISettings;
  private currentQuestion: number;

  private accA: number = 0;
  private accQ: number = 0;

  private results: IRIResultados[] = [];

  constructor(gameId: string, settings: IRISettings) {
    this.settings = settings;
    this.data = {
      gameId: gameId,
      answers: {},
    };

    this.accA = 0;
    this.accQ = 0;
    this.results = [];

    for (let i = 0; i < this.settings.questions.length; i++) {
      this.settings.questions[i].reverse = Rand.random() > 0.5;
    }

    this.currentQuestion = 0;
  }

  public collectedData(): IGameResults {
    this.data.score = this.accA / this.accQ;
    this.data.results = JSON.stringify(this.results);
    return this.data;
  }

  public resetData(): void {
    this.data.answers = {};
    this.currentQuestion = 0;
    this.results = [];
  }

  public getQuestion() {
    return {
      ...this.settings.questions[this.currentQuestion],
      options: this.settings.options,
    };
  }

  public questionAmount(): number {
    return this.settings.questions.length;
  }

  public questionIndex(): number {
    return this.currentQuestion;
  }

  public setAnswer(id: string, response: string, elapsedTime: number) {
    let answer: IAnswer = {
      value: response,
      elapsedTime: elapsedTime,
    };

    let valor: number = parseFloat(response);
    this.accA += valor;
    this.accQ++;

    this.results.push({
      situacion: this.settings.questions[this.currentQuestion].situation,
      valor: valor,
    });

    if (this.data.answers[id] === undefined) {
      this.data.answers[id] = {
        selected: answer,
        historical: [],
      };
    } else {
      this.data.answers[id].selected = answer;
    }

    this.currentQuestion++;
  }

  public setAnswerHistorical(
    id: string,
    response: string,
    elapsedTime: number
  ) {
    let answer: IAnswer = {
      value: response,
      elapsedTime: elapsedTime,
    };

    if (this.data.answers[id] === undefined) {
      this.data.answers[id] = {
        selected: { value: '', elapsedTime: 0 },
        historical: [],
      };
    }

    this.data.answers[id].historical.push(answer);
  }

  public setAsLastGame(last: boolean) {
    this.data.end = last;
  }
}

export default ResponsabilidadImagenesGame;
