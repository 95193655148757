import { useAppContext } from '../../common/AppContext';
import MentirosoGame from './MentirosoGame';
import logo from './../../../assets/images/cierre.gif';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import { AppReducerActionTypes } from '../../common/AppReducer';
import ReactGA from 'react-ga4';

const M2_4 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/mentiroso-2/4' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/mentiroso-2/4',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const game = appState.currentScreen.game as MentirosoGame;
  const score = game.getScore() + appState.mentirosoScore;

  ScreenUtils.hideBG();

  function next() {
    appDispatch({
      type: AppReducerActionTypes.ACCUMULATE_MENTIROSO,
      payload: game.getScore(),
    });
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  return (
    <>
      <img src={logo} alt='logo' className={'logoSolapa'} />
      <p className={'textStrong textL'}>¡Bien hecho!</p>
      <div>
        <p>
          Llevás ganados:
          <br />
          <span className='textL'>{score} pesos</span>
        </p>
      </div>
      <p></p>
      <div className='bottomButtonsSingleRight'>
        <button className='nextButton' onClick={next}>
          Continuar
        </button>
      </div>
    </>
  );
};

export default M2_4;
