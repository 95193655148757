import { useAppContext } from '../../common/AppContext';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import MemoriaTrabajoImagenesGame from './MemoriaTrabajoImagenesGame';
import { useElapsedTime } from 'use-elapsed-time';
import Figuras from './Figuras';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

const MTI_6 = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  let game = appState.currentScreen.game as MemoriaTrabajoImagenesGame;
  let question = game.getQuestion();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page:
        '/memoria-trabajo-imagenes/6/' +
        game.questionStage() +
        '/' +
        game.questionIndex(),
    });

    ReactGA.event({
      category: 'vista',
      action: 'render',
      label:
        '/memoria-trabajo-imagenes/6/' +
        game.questionStage() +
        '/' +
        game.questionIndex(),
      nonInteraction: true,
    });
  }, [game]);

  useElapsedTime({
    isPlaying: true,
    duration: question.questionTimeout,
    onComplete: () => {
      pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
    },
  });

  const figuras = Figuras();

  return (
    <>
      <div className={'buttonBG  flexRowLayout mtiPregunta'}>
        {question.question.map((item, index) => (
          <img src={figuras[item]} alt='' key={index} />
        ))}
      </div>
      <p></p>
    </>
  );
};

export default MTI_6;
