import { ScreenUtils } from '../../../utils/ScreenUtils';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import logo from './../../../assets/images/despedida.gif';
import ReactGA from 'react-ga4';

const D_0 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/despedida/0' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/despedida/0',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();

  ScreenUtils.hideBG();

  function next() {
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  return (
    <>
      <img src={logo} alt='logo' className={'logoSolapa'} />
      <p className={'textStrong textL'}>¡Felicitaciones!</p>
      <p className={'sideMargin50'}>Llegaste al final.</p>
      <p className={'sideMargin50'}>Completaste todo el proceso.</p>
      <p></p>
      <p></p>
      <div className='bottomButtonsSingleRight'>
        <button className='nextButton' onClick={next}>
          Continuar
        </button>
      </div>
    </>
  );
};

export default D_0;
