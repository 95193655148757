import { useAppContext } from '../../common/AppContext';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import logo from './../../../assets/images/entrevista.png';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import CuestionarioManejoFinancieroContent from './CuestionarioManejoFinancieroContent';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

const MF_0 = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/cuestionario-manejo-financiero/0',
    });

    ReactGA.event({
      category: 'vista',
      action: 'render',
      label: '/cuestionario-manejo-financiero/0',
      nonInteraction: true,
    });
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const game = appState.currentScreen
    .game as CuestionarioManejoFinancieroContent;

  const [checkValue, setCheckValue] = useState(false);

  ScreenUtils.hideBG();

  function next() {
    game.setAccepted();
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  return (
    <>
      <p></p>
      <p className={'textStrong textL'}></p>
      <img src={logo} alt='logo' className={'logoSolapa sideMargin50'} />
      <p className='sideMargin50'>
        Las siguientes preguntas están relacionadas con el motivo del préstamo y
        tu situación financiera.
      </p>
      <p className='sideMargin50'>
        Es muy importante que respondas con TOTAL SINCERIDAD. Nuestro sistema
        detectará cualquier inconsistencia.
      </p>

      <div>
        <label className='mfLabelAcepto'>
          <input
            type='checkbox'
            id='acceptCheck'
            name='acepto'
            checked={checkValue}
            onChange={check => {
              setCheckValue(check.currentTarget.checked);
            }}
          />
          Acepto
        </label>
      </div>
      <p></p>
      <div className='bottomButtonsSingleRight'>
        <button
          id='nextButton'
          className='nextButton'
          onClick={next}
          disabled={!checkValue}
        >
          Continuar
        </button>
      </div>
    </>
  );
};

export default MF_0;
