import { useReducer } from 'react';
import { useAppContext } from '../../common/AppContext';
import Header from '../../common/Header';
import PageFlow from '../../common/PageFlow';
import PageScreenContext from '../../common/PageScreenContext';
import { pageScreenReducer } from '../../common/PageScreenReducer';

import GR_0 from './GR_0';
import GR_1 from './GR_1';
import GR_2 from './GR_2';
import GR_3 from './GR_3';

import GratificacionRetrasadaGame from './GratificacionRetrasadaGame';

let screens: JSX.Element[] = [];

const GratificacionRetrasada = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const game = appState.currentScreen.game as GratificacionRetrasadaGame;
  game.setAsLastGame(appState.currentScreen.last);

  function prepareScreens() {
    screens = [];

    screens.push(<GR_0 />);

    for (let j = 0; j < game.stagesAmount(); j++) {
      screens.push(<GR_1 />);

      for (let i = 0; i < game.questionAmountPerStage(j); i++) {
        screens.push(<GR_2 />);
      }
    }

    screens.push(<GR_3 />);
  }

  prepareScreens();

  const initialState = { index: 0, length: screens.length };
  const [pageState, pageDispatch] = useReducer(pageScreenReducer, initialState);

  const providerState = {
    state: pageState,
    dispatch: pageDispatch,
  };

  return (
    <PageScreenContext.Provider value={providerState}>
      <Header>
        <PageFlow children={screens[pageState.index]} />
      </Header>
    </PageScreenContext.Provider>
  );
};

export default GratificacionRetrasada;
