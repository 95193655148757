import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useElapsedTime } from 'use-elapsed-time';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import { useAppContext } from '../../common/AppContext';
import Dots from '../../common/Dots';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import AutocontrolGame from './AutocontrolGame';
import ReactGA from 'react-ga4';

const AC_1 = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const game = appState.currentScreen.game as AutocontrolGame;

  const [radioValue, setRadioValue] = useState('');

  ScreenUtils.showBG();

  let elapsedTime = useElapsedTime({
    isPlaying: true,
  });

  const amount = game.questionAmount();
  const current = game.questionIndex();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/autocontrol/1/' + current,
    });

    ReactGA.event({
      category: 'vista',
      action: 'render',
      label: '/autocontrol/1/' + current,
      nonInteraction: true,
    });
  }, [current]);

  function onChanged(event: ChangeEvent<HTMLInputElement>) {
    // console.log('historical: ' + question.id + ' => ' + event.target.value);
    game.setAnswerHistorical(
      question.id,
      event.target.value,
      elapsedTime.elapsedTime
    );
    setRadioValue(event.target.value);
  }

  function next(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(
      event.target as HTMLFormElement & EventTarget
    );

    const formDictionary = Object.fromEntries(formData.entries());

    let id: string = formDictionary.id.toString();
    let seleccion: string = formDictionary.frase?.toString();

    // TODO remover esto cuando este implementado el disabling del boton
    if (seleccion === undefined) {
      seleccion = 'sin seleccion';
    }

    game.setAnswer(id, seleccion, elapsedTime.elapsedTime);

    elapsedTime.reset();
    setRadioValue('');

    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  const question = game.getQuestion();

  interface IRadio {
    name: string;
    value: string;
  }

  let radios: IRadio[] = [];

  question.options.forEach(option =>
    radios.push({ name: option.text, value: option.id })
  );

  return (
    <>
      <form
        onSubmit={next}
        key='formulariousuario'
        className='flexColumnLayout'
        id='acForm'
      >
        <p></p>
        <Dots amount={amount} active={current} key='dots' />
        <input type='hidden' name='id' value={question.id} />

        {radios.map((radio, index) => (
          <label key={'d' + index}>
            <input
              key={index}
              id={`radio-${index}`}
              type='radio'
              name='frase'
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={onChanged}
              data-radio='image'
            />
            <div data-radio-type='autocontrol' className='flexRowLayout'>
              {radio.name}
            </div>
          </label>
        ))}
        <p></p>

        <div className='bottomButtonsSingleRight'>
          <button className='nextButton' disabled={radioValue.length === 0}>
            Continuar
          </button>
        </div>
      </form>
    </>
  );
};

export default AC_1;
