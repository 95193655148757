import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import MemoriaTrabajoNumerosGame, {
  MTNStates,
} from './MemoriaTrabajoNumerosGame';
import { useAppContext } from '../../common/AppContext';
import deleteIcon from './../../../assets/images/delete.png';
import enterIcon from './../../../assets/images/enter.png';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

const MTN_3 = () => {
  const [localState, setLocalState] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const game = appState.currentScreen.game as MemoriaTrabajoNumerosGame;

  const answerTimeout = game.getAnswerTimeOut();

  ScreenUtils.showBG();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page:
        '/memoria-trabajo-numeros/3/' +
        game.questionStage() +
        '/' +
        game.questionIndex(),
    });
    ReactGA.event({
      category: 'vista',
      action: 'render',
      label: '/memoria-trabajo-numeros/3',
      nonInteraction: true,
    });
  }, [game]);

  let elapsedTime: number;
  const countdownNumber = (remainingTime: any) => {
    elapsedTime = remainingTime.elapsedTime;
    return <div className='timerText'>{remainingTime.remainingTime}</div>;
  };

  function timedOut(totalElapsedTime: number) {
    const finish: boolean = game.setAnswer(totalElapsedTime, true);

    if (finish) {
      switch (game.getState()) {
        case MTNStates.FINISHED:
          pageDispatch({ type: PageScreenReducerActionTypes.JUMP, payload: 2 });
          break;
        default:
          pageDispatch({
            type: PageScreenReducerActionTypes.JUMP,
            payload: -2,
          });
          break;
      }
    } else {
      let jump = 0;
      switch (game.getState()) {
        case MTNStates.TUTORIAL:
          jump = -1;
          break;
        case MTNStates.TEST:
          jump = -1;
          break;
      }
      pageDispatch({ type: PageScreenReducerActionTypes.JUMP, payload: jump });
    }
  }

  function enteredNumber(n: number) {
    let stateCopy = [...localState, n.toString()];
    setLocalState(stateCopy);
    game.cacheAnswer(stateCopy);
  }

  function deletedNumber() {
    let stateCopy = [...localState];
    stateCopy.pop();
    setLocalState(stateCopy);
    game.cacheAnswer(stateCopy);
  }

  function enteredEnter() {
    const finish: boolean = game.setAnswer(elapsedTime, false);

    if (finish) {
      switch (game.getState()) {
        case MTNStates.FINISHED:
          pageDispatch({ type: PageScreenReducerActionTypes.JUMP, payload: 2 });
          break;
        default:
          pageDispatch({
            type: PageScreenReducerActionTypes.JUMP,
            payload: -2,
          });
          break;
      }
    } else {
      // console.log(game.getStage());

      let jump = 0;
      switch (game.getState()) {
        case MTNStates.TUTORIAL:
          jump = -1;
          break;
        case MTNStates.TEST:
          jump = -1;
          break;
      }
      pageDispatch({ type: PageScreenReducerActionTypes.JUMP, payload: jump });
    }
  }

  let digitos: JSX.Element[] = [];

  const totalDigits = game.getQuestion().length;

  for (let i = 0; i < totalDigits; i++) {
    digitos.push(
      <span className='answerDigit' key={'digit' + i}>
        {localState[i] || '\u00A0\u00A0'}
      </span>
    );
  }

  const numbersDisabled: boolean = localState.length >= totalDigits;
  const deleteDisabled: boolean = localState.length < 1;
  const enterDisabled: boolean = localState.length < totalDigits;

  let countdown: JSX.Element = (
    <p>Luego deberás escribirla en el mismo orden que aparecieron.</p>
  );

  if (answerTimeout !== Infinity) {
    countdown = (
      <CountdownCircleTimer
        key={'TIMER' + game.getCurrentId()}
        isPlaying
        duration={answerTimeout}
        colors={[
          ['#004777', 0.33],
          ['#F7B801', 0.33],
          ['#A30000', 0.0],
        ]}
        trailColor={'#ffffff00'}
        size={100}
        onComplete={timedOut}
        strokeWidth={10}
        strokeLinecap={'round'}
        children={countdownNumber}
      ></CountdownCircleTimer>
    );
  }

  let titulo = '¿Cuál es el número?';

  if (game.getState() !== MTNStates.INSTRUCTIONS) {
    titulo = '';
  }

  return (
    <>
      {countdown}
      <div className='sideMargin50'>
        <p className='textL textStrong'>{titulo}</p>
      </div>
      <div className='answerDigits flexRowLayout'>{digitos}</div>

      <div className='gridLayout grid5'>
        <div>&nbsp;</div>
        <button
          onClick={() => {
            enteredNumber(1);
          }}
          disabled={numbersDisabled}
        >
          1
        </button>
        <button
          onClick={() => {
            enteredNumber(2);
          }}
          disabled={numbersDisabled}
        >
          2
        </button>
        <button
          onClick={() => {
            enteredNumber(3);
          }}
          disabled={numbersDisabled}
        >
          3
        </button>
        <div>&nbsp;</div>

        <div>&nbsp;</div>
        <button
          onClick={() => {
            enteredNumber(4);
          }}
          disabled={numbersDisabled}
        >
          4
        </button>
        <button
          onClick={() => {
            enteredNumber(5);
          }}
          disabled={numbersDisabled}
        >
          5
        </button>
        <button
          onClick={() => {
            enteredNumber(6);
          }}
          disabled={numbersDisabled}
        >
          6
        </button>
        <div>&nbsp;</div>

        <div>&nbsp;</div>
        <button
          onClick={() => {
            enteredNumber(7);
          }}
          disabled={numbersDisabled}
        >
          7
        </button>
        <button
          onClick={() => {
            enteredNumber(8);
          }}
          disabled={numbersDisabled}
        >
          8
        </button>
        <button
          onClick={() => {
            enteredNumber(9);
          }}
          disabled={numbersDisabled}
        >
          9
        </button>
        <div>&nbsp;</div>

        <div>&nbsp;</div>
        <button
          onClick={() => {
            deletedNumber();
          }}
          disabled={deleteDisabled}
        >
          <img src={deleteIcon} alt='delete' />
        </button>
        <button
          onClick={() => {
            enteredNumber(0);
          }}
          disabled={numbersDisabled}
        >
          0
        </button>
        <button
          onClick={() => {
            enteredEnter();
          }}
          disabled={enterDisabled}
        >
          <img src={enterIcon} alt='enter' />
        </button>
        <div>&nbsp;</div>
      </div>
    </>
  );
};

export default MTN_3;
