import { useElapsedTime } from 'use-elapsed-time';
import { useAppContext } from '../../common/AppContext';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import MemoriaTrabajoNumerosGame, {
  MTNStates,
} from './MemoriaTrabajoNumerosGame';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

const MTN_2 = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const game = appState.currentScreen.game as MemoriaTrabajoNumerosGame;
  const question = game.getQuestion();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/memoria-trabajo-numeros/2' });
    ReactGA.event({
      category: 'vista',
      action: 'render',
      label: '/memoria-trabajo-numeros/2',
      nonInteraction: true,
    });
  }, []);

  let elapsedTime = useElapsedTime({
    isPlaying: true,
    duration: question.length * game.getDigitTimeOut(),
    onComplete: () => {
      next();
    },
  });

  function next() {
    elapsedTime.reset();
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  let titulo = 'Visualizarás una secuencia de números. ¡Memorizala bien!';

  if (game.getState() !== MTNStates.INSTRUCTIONS) {
    titulo = '';
  }

  const index = Math.floor(elapsedTime.elapsedTime / game.getDigitTimeOut());

  let digito: number = question[index];

  return (
    <>
      <div className='sideMargin50'>
        <p className='textL textStrong'>{titulo}</p>
      </div>
      <div className='mtnBigNumber buttonBG flexRowLayoutSA'>{digito}</div>
      <p></p>
      <p></p>
      <p></p>
    </>
  );
};

export default MTN_2;
