import { IGame } from '../../../interfaces/IGame';
import { IGameResults } from '../../../interfaces/IGameResults';

interface IAnswer {
  value: string;
  option: string;
  elapsedTime: number;
  timedOut: boolean;
}

interface IACResults extends IGameResults {
  answers: {
    [key: string]: {
      [key: string]: { selected: IAnswer; historical: IAnswer[] };
    };
  };
  hoyCounter: number;
  futuroCounter: number;
}

interface IOptions {
  id: string;
  text: string;
}

interface IQuestions {
  id: string;
  options: IOptions[];
}

export interface IStages {
  id: string;
  answerTimeout: number;
  questionAmount: number;
  months: number;
  H: number;
  F: number;
  h: number;
  f: number;
}

interface IACSettings {
  stages: IStages[];
}

class GratificacionRetrasadaGame implements IGame {
  private data: IACResults;
  private settings: IACSettings;
  private currentQuestionIndex: number;
  private currentStage: number;
  private hoyValue: number;
  private futuroValue: number;
  private currentQuestion: IQuestions;
  private hasTimedOut: boolean;

  private accA: number;
  private accQ: number;

  private results: string[] = [];

  constructor(gameId: string, settings: IACSettings) {
    this.settings = settings;

    this.data = {
      gameId: gameId,
      answers: {},
      futuroCounter: 0,
      hoyCounter: 0,
      score: 0,
    };

    this.currentQuestionIndex = 0;
    this.currentStage = 0;
    this.hoyValue = 0;
    this.futuroValue = 0;
    this.hasTimedOut = false;

    this.accA = 0;
    this.accQ = 0;

    this.currentQuestion = {
      id: '',
      options: [],
    };

    this.generateQuestion('first');
  }

  public collectedData(): IGameResults {
    this.data.score = this.getScore();
    this.data.results = JSON.stringify(this.results);
    return this.data;
  }

  public resetData(): void {
    this.data.answers = {};
    this.data.score = 0;
    this.currentQuestionIndex = 0;
    this.currentStage = 0;

    this.accA = 0;
    this.accQ = 0;

    this.results = [];
  }

  private generateQuestion(option: string) {
    switch (option) {
      case 'f':
        this.hoyValue += this.settings.stages[this.currentStage].h;
        break;
      case 'h':
        this.futuroValue += this.settings.stages[this.currentStage].f;
        break;
      case 'first':
        this.hoyValue = this.settings.stages[this.currentStage].H;
        this.futuroValue = this.settings.stages[this.currentStage].F;
        break;
      case 'sin seleccion':
        break;
      default:
        console.error('opcion', option, 'desconocida');
        break;
    }

    this.accA += this.futuroValue / this.hoyValue;
    this.accQ++;

    this.currentQuestion = {
      id: this.currentQuestionIndex.toString(),
      options: [
        {
          id: 'h',
          text: this.hoyValue.toString(),
        },
        {
          id: 'f',
          text: this.futuroValue.toString(),
        },
      ],
    };
  }

  public getQuestion(): IQuestions {
    return this.currentQuestion;
  }

  public questionAmount(): number {
    return this.settings.stages[this.currentStage].questionAmount;
  }

  public questionAmountPerStage(stage: number): number {
    return this.settings.stages[stage].questionAmount;
  }

  public questionIndex(): number {
    return this.currentQuestionIndex;
  }

  public questionStage(): number {
    return this.currentStage;
  }

  public stagesAmount(): number {
    return this.settings.stages.length;
  }

  public getPreference(): number {
    return this.data.hoyCounter - this.data.futuroCounter;
  }

  public getScore(): number {
    return this.accA / this.accQ;
  }

  public getTimeOut(): number {
    return this.settings.stages[this.currentStage].answerTimeout;
  }

  public setTimedOut() {
    this.hasTimedOut = true;
  }

  public setAnswer(id: string, response: string, elapsedTime: number) {
    // TODO agregar el valor elegido ademas de la respuesta
    let answer: IAnswer = {
      value: response,
      elapsedTime: elapsedTime,
      option: (response === 'f' ? this.hoyValue : this.futuroValue).toString(),
      timedOut: this.hasTimedOut,
    };

    switch (response) {
      case 'h':
        this.data.hoyCounter++;
        this.results.push('h');
        break;
      case 'f':
        this.data.futuroCounter++;
        this.results.push('f');
        break;
      case 'sin seleccion':
        this.results.push('-');
        break;
      default:
        console.error('opcion', response, 'desconocida');
        break;
    }

    this.hasTimedOut = false;

    if (this.data.answers[this.currentStage] === undefined) {
      this.data.answers[this.currentStage] = {};
    }

    if (this.data.answers[this.currentStage][id] === undefined) {
      this.data.answers[this.currentStage][id] = {
        selected: answer,
        historical: [],
      };
    } else {
      this.data.answers[this.currentStage][id].selected = answer;
    }

    this.currentQuestionIndex++;

    if (this.currentQuestionIndex >= this.questionAmount()) {
      this.currentQuestionIndex = 0;
      this.currentStage++;
      if (this.currentStage < this.settings.stages.length) {
        this.generateQuestion('first');
      }
    } else {
      this.generateQuestion(response);
    }
  }

  public setAnswerHistorical(
    id: string,
    response: string,
    elapsedTime: number
  ) {
    let answer: IAnswer = {
      value: response,
      elapsedTime: elapsedTime,
      option: (response === 'f' ? this.hoyValue : this.futuroValue).toString(),
      timedOut: false,
    };

    if (this.data.answers[this.currentStage] === undefined) {
      this.data.answers[this.currentStage] = {};
    }

    if (this.data.answers[this.currentStage][id] === undefined) {
      this.data.answers[this.currentStage][id] = {
        selected: { value: '', elapsedTime: 0, option: '', timedOut: false },
        historical: [],
      };
    }

    this.data.answers[this.currentStage][id].historical.push(answer);
  }

  public setAsLastGame(last: boolean) {
    this.data.end = last;
  }
}

export default GratificacionRetrasadaGame;
