import { useReducer } from 'react';
import { useAppContext } from '../../common/AppContext';
import Header from '../../common/Header';
import PageFlow from '../../common/PageFlow';
import PageScreenContext from '../../common/PageScreenContext';
import { pageScreenReducer } from '../../common/PageScreenReducer';
import MemoriaTrabajoImagenesGame from './MemoriaTrabajoImagenesGame';
import MTI_0 from './MTI_0';
import MTI_1 from './MTI_1';
import MTI_2 from './MTI_2';
import MTI_3 from './MTI_3';
import MTI_4 from './MTI_4';
import MTI_5 from './MTI_5';
import MTI_6 from './MTI_6';
import MTI_7 from './MTI_7';
import MTI_8 from './MTI_8';
// import MTI_8 from './MTI_8';
import MTI_9 from './MTI_9';

let screens: JSX.Element[] = [];

const MemoriaTrabajoImagenes = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const game = appState.currentScreen.game as MemoriaTrabajoImagenesGame;
  game.setAsLastGame(appState.currentScreen.last);

  function prepareScreens() {
    screens = [];

    screens.push(<MTI_0 />);
    if (game.hasTutorial()) {
      screens.push(<MTI_1 />);
      screens.push(<MTI_2 />);
      screens.push(<MTI_3 />);
    }
    screens.push(<MTI_4 />);
    screens.push(<MTI_5 />);
    screens.push(<MTI_6 />);
    screens.push(<MTI_7 />);
    screens.push(<MTI_8 />);
    screens.push(<MTI_9 />);
  }

  prepareScreens();

  const initialState = { index: 0, length: screens.length };
  const [pageState, pageDispatch] = useReducer(pageScreenReducer, initialState);

  const providerState = {
    state: pageState,
    dispatch: pageDispatch,
  };

  return (
    <Header>
      <PageScreenContext.Provider value={providerState}>
        <PageFlow children={screens[pageState.index]} />
      </PageScreenContext.Provider>
    </Header>
  );
};

export default MemoriaTrabajoImagenes;
