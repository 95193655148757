import { useAppContext } from '../../common/AppContext';
import { AppReducerActionTypes } from '../../common/AppReducer';
import { SaveResults } from '../../common/SaveResults';
import GratificacionRetrasadaGame from './GratificacionRetrasadaGame';
import logo from './../../../assets/images/cierre.gif';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import { ShowModal } from '../../../utils/ShowModal';
import { useState } from 'react';
import ReactGA from 'react-ga4';

const GR_3 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/gratificacion-retrasada/3' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/gratificacion-retrasada/3',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const [disableContinuar, setDisableContinuar] = useState(false);

  const game = appState.currentScreen.game as GratificacionRetrasadaGame;

  ScreenUtils.hideBG();

  // TODO consultar con Seba
  const preferencia =
    game.getPreference() < 0
      ? 'Preferís esperar para tener más dinero.'
      : 'Preferís contar con el dinero en el momento.';

  function next() {
    setDisableContinuar(true);
    SaveResults(
      game.collectedData(),
      appState.bearer_token,
      () => {
        setDisableContinuar(false);
        pageDispatch({ type: PageScreenReducerActionTypes.RESET });
        appDispatch({ type: AppReducerActionTypes.NEXT });
      },
      (connectionError: boolean, code: string, message: string) => {
        ShowModal(connectionError, appDispatch, code, message);
        setDisableContinuar(false);
      }
    );
  }

  return (
    <>
      <img src={logo} alt='logo' className={'logoSolapa'} />

      <p className={'textStrong textL'}>¡Muy bien!</p>

      <p className='sideMargin50'>{preferencia}</p>
      <p>¿Continuamos?</p>
      <p></p>
      <p></p>
      <div className='bottomButtonsSingleRight'>
        <button
          className='nextButton'
          onClick={next}
          disabled={disableContinuar}
        >
          Continuar
        </button>
      </div>
    </>
  );
};

export default GR_3;
