import { Rand } from './Rand';

export class Sequences {
  static generate(amount: number) {
    let sequence: number[] = [];

    for (let i = 0; i < amount; i++) {
      sequence.push(i);
    }

    return sequence;
  }

  static sample(sequence: number[], amount: number) {
    let samples: number[] = [];
    let bucket = [...sequence];

    for (let i = 0; i < amount; i++) {
      let index = Math.trunc(Rand.random() * bucket.length);
      samples.push(bucket.splice(index, 1)[0]);
    }

    return samples;
  }

  static sampleAndRemainder(sequence: number[], amount: number) {
    let samples: number[] = [];
    let bucket = [...sequence];

    for (let i = 0; i < amount; i++) {
      let index = Math.trunc(Rand.random() * bucket.length);
      samples.push(bucket.splice(index, 1)[0]);
    }

    return [samples, bucket];
  }

  static compare(a: number[], b: number[]): boolean {
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }

    return true;
  }
}
