import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useAppContext } from '../../common/AppContext';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import MemoriaTrabajoImagenesGame, {
  MTIStates,
} from './MemoriaTrabajoImagenesGame';
import Figuras from './Figuras';
import ReactGA from 'react-ga4';

const MTI_7 = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const game = appState.currentScreen.game as MemoriaTrabajoImagenesGame;

  let elapsedTime: number;

  ReactGA.send({
    hitType: 'pageview',
    page:
      '/memoria-trabajo-imagenes/7/' +
      game.questionStage() +
      '/' +
      game.questionIndex(),
  });

  ReactGA.event({
    category: 'vista',
    action: 'render',
    label:
      '/memoria-trabajo-imagenes/7/' +
      game.questionStage() +
      '/' +
      game.questionIndex(),
    nonInteraction: true,
  });

  const countdownNumber = (remainingTime: any) => {
    elapsedTime = remainingTime.elapsedTime;
    return <div className='timerText'>{remainingTime.remainingTime}</div>;
  };

  function setAnswer(value: number) {
    const shouldFinish = game.setAnswer(value, elapsedTime);

    if (shouldFinish) {
      let state = game.getState();
      switch (state) {
        case MTIStates.TUTORIAL_FAILED:
          pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
          break;
        case MTIStates.FINISHED:
          pageDispatch({ type: PageScreenReducerActionTypes.JUMP, payload: 2 });
          break;
      }
    } else {
      pageDispatch({ type: PageScreenReducerActionTypes.JUMP, payload: -1 });
    }
  }

  let question = game.getQuestion();
  let components: JSX.Element[] = [];

  let counter = 0;
  function createKey() {
    return counter++;
  }

  const figuras = Figuras();

  for (let i = 0; i < question.answers.length; i++) {
    components.push(
      <button
        className={'buttonBG buttonShadow flexRowLayoutSA'}
        onClick={() => setAnswer(i)}
        id={createKey().toString()}
        key={createKey().toString()}
      >
        {question.answers[i].map(item => (
          <img
            src={figuras[item]}
            alt=''
            key={createKey().toString()}
            className='mtiRespuesta'
          />
        ))}
      </button>
    );
  }

  return (
    <>
      <CountdownCircleTimer
        isPlaying
        duration={question.answerTimeout}
        colors={[
          ['#004777', 0.33],
          ['#F7B801', 0.33],
          ['#A30000', 0.0],
        ]}
        trailColor={'#ffffff00'}
        size={100}
        onComplete={() => {
          setAnswer(-1);
        }}
        strokeWidth={10}
        strokeLinecap={'round'}
        children={countdownNumber}
      ></CountdownCircleTimer>
      <div className='gridLayout'>{components.map(component => component)}</div>
      <p></p>
      <p></p>
    </>
  );
};

export default MTI_7;
