import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import logo from './../../../assets/images/formulariousuario.png';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import ReactGA from 'react-ga4';

const FU_0 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/formulario-usuario/0' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/formulario-usuario/0',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();

  function next() {
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  ScreenUtils.hideBG();

  return (
    <>
      <div className='sideMargin50'>
        <p className={'textStrong textL'}></p>
        <img src={logo} alt='logo' className={'logoSolapa'} />
        <p>
          Primero voy a hacerte unas preguntas simples para conocerte más, y
          luego haremos una serie de actividades para ofrecerte la mejor opción.
        </p>
        <p> ¿Te parece?</p>
      </div>
      <div className='bottomButtonsSingleCenter'>
        <button className='nextButton widerButton' onClick={next}>
          Comenzar Entrevista
        </button>
      </div>
    </>
  );
};

export default FU_0;
