import axios from 'axios';
import { IGameResults } from '../../interfaces/IGameResults';
import { ScreenUtils } from '../../utils/ScreenUtils';
import ReactGA from 'react-ga4';

export function SaveResults(
  data: IGameResults,
  bearerToken: string,
  callback: () => void,
  errorCallback: (connection: boolean, code: string, message: string) => void
) {
  let tempString = JSON.stringify(data);
  let copiedData = JSON.parse(tempString);

  console.log(copiedData);
  // TODO activity indicator graphic

  let URL = process.env.REACT_APP_API_URL || '';

  if (!URL) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      callback();
      return;
    } else {
      console.error('no hay URL definida');
      throw new Error('No hay una URL definida');
    }
  }

  const headers = {
    headers: { Authorization: bearerToken },
  };

  axios.defaults.timeout = 5000;

  axios
    .post(URL + 'savegame', copiedData, headers)
    .then(result => {
      callback();
    })
    .catch(reason => {
      console.error(reason);
      ScreenUtils.showLoading();
      axios
        .post(URL + 'savegame', copiedData, headers)
        .then(result => {
          console.log(result);
          console.log(result.data);
          ScreenUtils.hideLoading();
          callback();
        })
        .catch(reason => {
          ReactGA.event({
            category: 'error',
            action: 'guardado',
            label: copiedData.gameId,
            nonInteraction: true,
          });

          console.error(reason);
          ScreenUtils.hideLoading();

          let message = 'Error desconocido';
          let code = '0';

          if (reason.response) {
            if (reason.response.data) {
              code = reason.response.data.statusCode;
              message = reason.response.data.message;
            }
          } else {
            message = reason.message;
            code = reason.request?.status;
          }

          errorCallback(reason.request.status === 0, code, message);
        });
    });
}
