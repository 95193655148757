import { IGame } from '../../../interfaces/IGame';
import { Sequences } from '../../../utils/Sequences';
import { IGameResults } from '../../../interfaces/IGameResults';

export enum MTNStates {
  INSTRUCTIONS,
  TUTORIAL,
  TEST,
  FINISHED,
  TUTORIAL_FAILED,
}

export interface IMTNSettings {
  tutorial: IStage;
  test: IStage;
  instructions: IStage;
}

export interface IStage {
  min: number;
  max: number;
  retries: number;
  digitTimeout: number;
  answerTimeout: number;
}

interface IUserAnswer {
  id: string;
  correct: boolean;
  timedOut: boolean;
  elapsedTime: number;
  question: number[];
  answer: number[];
  tutorial: boolean;
}

interface IUserSlider {
  value: string;
  elapsedTime: number;
}

interface IMTNResults extends IGameResults {
  answers: IUserAnswer[];
  performance: {
    [key: string]: { selected: IUserSlider; historical: IUserSlider[] };
  };
  tutorialRetries: number;
  tutorialRestarts: number;
  tutorialSkips: number;
  testRetries: number;
  rightAnswers: number;
}

class MemoriaTrabajoNumerosGame implements IGame {
  private settings: IMTNSettings;
  private data: IMTNResults;
  private currentQuestion: number;
  private question: number[];
  private state: MTNStates;
  private cachedAnswer: string[];
  private questionId: number;
  private correctAnswers: number;

  constructor(gameId: string, settings: IMTNSettings) {
    this.settings = settings;
    this.settings.instructions = {
      min: 3,
      max: 3,
      retries: 0,
      digitTimeout: 1,
      answerTimeout: Infinity,
    };
    this.data = {
      gameId: gameId,
      answers: [],
      performance: {},
      tutorialRetries: 0,
      tutorialRestarts: 0,
      tutorialSkips: 0,
      testRetries: 0,
      rightAnswers: 0,
    };
    this.question = [];
    this.currentQuestion = 0;
    this.state = MTNStates.INSTRUCTIONS;
    this.generateQuestion();
    this.cachedAnswer = [];
    this.questionId = 0;
    this.correctAnswers = 0;
  }

  private getParams(): IStage {
    let res: IStage = this.settings.test;
    switch (this.state) {
      case MTNStates.INSTRUCTIONS:
        res = this.settings.instructions;
        break;
      case MTNStates.TUTORIAL:
        res = this.settings.tutorial;
        break;
      case MTNStates.TEST:
        res = this.settings.test;
        break;
    }
    return res;
  }

  public getState(): MTNStates {
    return this.state;
  }

  public skipTutorial() {
    if (this.state === MTNStates.TUTORIAL_FAILED) {
      this.state = MTNStates.TEST;
      this.currentQuestion = 0;
      this.data.tutorialSkips++;
    }
  }

  public tutorialRestart() {
    this.data.tutorialRestarts++;
  }

  public resetData(): void {
    this.data.answers = [];
    this.data.performance = {};

    this.data.testRetries = 0;
    this.data.rightAnswers = 0;
    this.currentQuestion = 0;
    this.state = MTNStates.INSTRUCTIONS;
    this.questionId = 0;
    this.correctAnswers = 0;

    this.generateQuestion();
  }

  private generateQuestion() {
    let params = this.getParams();

    let sequence = Sequences.generate(10);
    let question = Sequences.sample(
      sequence,
      params.min + this.currentQuestion
    );

    this.question = question;
  }

  public collectedData(): IGameResults {
    this.data.score = this.getScore();
    this.data.results = JSON.stringify({
      correctas: this.correctAnswers,
      reintentos: this.data.testRetries,
    });
    return this.data;
  }

  public getCurrentId() {
    return this.questionId;
  }

  public getQuestion(): number[] {
    return this.question;
  }

  public getAnswerTimeOut(): number {
    let params = this.getParams();

    return params.answerTimeout;
  }

  public getDigitTimeOut(): number {
    let params =
      this.state === MTNStates.TUTORIAL
        ? this.settings.tutorial
        : this.settings.test;

    return params.digitTimeout;
  }

  public cacheAnswer(response: string[]) {
    this.cachedAnswer = response;
  }

  public setAnswer(elapsedTime: number, timedOut: boolean) {
    let filteredResponse: number[] = [];

    for (const digit of this.cachedAnswer) {
      filteredResponse.push(parseInt(digit));
    }

    let correct: boolean = true;

    if (this.cachedAnswer.length !== this.question.length) {
      correct = false;
    } else {
      for (var i = 0; i < filteredResponse.length; i++) {
        if (filteredResponse[i] !== this.question[i]) {
          correct = false;
          break;
        }
      }
    }

    let answer: IUserAnswer = {
      id: this.createId().toString(),
      correct: correct,
      timedOut: timedOut,
      elapsedTime: elapsedTime,
      question: this.question,
      answer: filteredResponse,
      tutorial: this.state === MTNStates.TUTORIAL,
    };

    if (this.state === MTNStates.TEST) {
      if (correct) {
        this.correctAnswers++;
      }
    }

    if (this.state !== MTNStates.INSTRUCTIONS) {
      this.data.answers.push(answer);
    }

    // console.log(answer);

    let finish: boolean = false;
    let params = this.getParams();

    if (correct) {
      this.currentQuestion++;

      if (this.state === MTNStates.TEST) {
        this.data.rightAnswers++;
      }
    } else {
      switch (this.state) {
        case MTNStates.INSTRUCTIONS:
          this.currentQuestion++;
          break;
        case MTNStates.TUTORIAL:
          this.data.tutorialRetries++;

          if (this.data.tutorialRetries % params.retries === 0) {
            if (this.data.tutorialRetries === params.retries) {
              this.state = MTNStates.TUTORIAL_FAILED;
              finish = true;
            } else {
              this.currentQuestion = 0;
              this.state++;
              finish = true;
              params = this.getParams();
            }
          }
          break;
        case MTNStates.TEST:
          this.data.testRetries++;

          if (this.data.testRetries >= params.retries) {
            this.state = MTNStates.FINISHED;
            finish = true;
          }
          break;
      }
    }

    if (this.currentQuestion > params.max - params.min) {
      this.currentQuestion = 0;
      this.state++;

      if (!this.hasTutorial() && this.state === MTNStates.TUTORIAL) {
        this.state = MTNStates.TEST;
      }

      finish = true;
      params = this.getParams();
    }

    if (this.state !== MTNStates.FINISHED) {
      this.generateQuestion();
    } else {
      this.question = [];
    }

    return finish;
  }

  public questionIndex(): number {
    return this.currentQuestion;
  }

  public questionStage(): number {
    return this.state;
  }

  private createId() {
    return this.questionId++;
  }

  public setPerformance(id: string, response: string, elapsedTime: number) {
    let slider: IUserSlider = {
      value: response,
      elapsedTime: elapsedTime,
    };

    if (this.data.performance[id] === undefined) {
      this.data.performance[id] = {
        selected: slider,
        historical: [],
      };
    } else {
      this.data.performance[id].selected = slider;
    }
  }

  public setPerformanceHistorical(
    id: string,
    response: string,
    elapsedTime: number
  ) {
    let slider: IUserSlider = {
      value: response,
      elapsedTime: elapsedTime,
    };

    if (this.data.performance[id] === undefined) {
      this.data.performance[id] = {
        selected: { value: '', elapsedTime: 0 },
        historical: [],
      };
    }

    this.data.performance[id].historical.push(slider);
  }

  public getScore(): number {
    let score: number =
      this.correctAnswers -
      this.data.testRetries / (this.settings.test.retries + 1);

    let totalQuestions: number =
      this.settings.test.max + 1 - this.settings.test.min;

    let corr_min =
      this.settings.test.retries / (this.settings.test.retries + 1);

    return (100.0 * (score + corr_min)) / (totalQuestions + corr_min);
  }

  public hasTutorial(): boolean {
    return this.settings.tutorial !== undefined;
  }

  public setAsLastGame(last: boolean) {
    this.data.end = last;
  }
}

export default MemoriaTrabajoNumerosGame;
