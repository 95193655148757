import { IGame } from '../../../interfaces/IGame';
import { IGameResults } from '../../../interfaces/IGameResults';
import { QuestionTypes } from '../../common/FormInputs/QuestionTypes';
import { IFormAnswer } from '../../common/FormInputs/IFormAnswer';
import { IFormOption } from '../../common/FormInputs/IFormOption';

interface IFUResults extends IGameResults {
  answers: {
    [key: string]: string;
  };
}

interface IFUSettings {
  questions: IFUQuestion[];
}

export interface IFUQuestion {
  id: string;
  question: string;
  options: IFormOption[] | null;
  type: QuestionTypes;
  min?: number | null;
  max?: number | null;
}

class FormularioUsuarioContent implements IGame {
  private settings: IFUSettings;
  private data: IFUResults;
  private valid: {
    [key: string]: boolean;
  };

  constructor(gameId: string) {
    this.settings = {
      questions: [
        {
          id: 'nombreapellido',
          question: 'Nombre y Apellido',
          options: null,
          type: QuestionTypes.text,
        },
        {
          id: 'telefono',
          question: 'Teléfono',
          options: null,
          type: QuestionTypes.text,
        },
        {
          id: 'dni',
          question: 'DNI',
          options: null,
          type: QuestionTypes.number,
          min: 1000000,
          max: 99999999,
        },
        {
          id: 'sexo',
          question: 'Sexo',
          options: [
            { id: 'm', option: 'M' },
            { id: 'f', option: 'F' },
          ],
          type: QuestionTypes.binary,
        },
        {
          id: 'provincia',
          question: 'Elegí una provincia',
          options: [
            { id: 'Buenos Aires', option: 'Buenos Aires' },
            {
              id: 'Ciudad Autónoma de Buenos Aires',
              option: 'Ciudad Autónoma de Buenos Aires',
            },
            { id: 'Catamarca', option: 'Catamarca' },
            { id: 'Chaco', option: 'Chaco' },
            { id: 'Chubut', option: 'Chubut' },
            { id: 'Córdoba', option: 'Córdoba' },
            { id: 'Corrientes', option: 'Corrientes' },
            { id: 'Entre Ríos', option: 'Entre Ríos' },
            { id: 'Formosa', option: 'Formosa' },
            { id: 'Jujuy', option: 'Jujuy' },
            { id: 'La Pampa', option: 'La Pampa' },
            { id: 'La Rioja', option: 'La Rioja' },
            { id: 'Mendoza', option: 'Mendoza' },
            { id: 'Misiones', option: 'Misiones' },
            { id: 'Neuquén', option: 'Neuquén' },
            { id: 'Río Negro', option: 'Río Negro' },
            { id: 'Salta', option: 'Salta' },
            { id: 'San Juan', option: 'San Juan' },
            { id: 'San Luis', option: 'San Luis' },
            { id: 'Santa Cruz', option: 'Santa Cruz' },
            { id: 'Santa Fe', option: 'Santa Fe' },
            { id: 'Santiago del Estero', option: 'Santiago del Estero' },
            {
              id: 'Tierra del Fuego',
              option: 'Tierra del Fuego, Antártida e Islas del Atlántico Sur',
            },
            { id: 'Tucumán', option: 'Tucumán' },
          ],
          type: QuestionTypes.drop,
        },
        {
          id: 'email',
          question: 'Correo electrónico',
          options: null,
          type: QuestionTypes.email,
        },
      ],
    };

    this.data = {
      gameId: gameId,
      answers: {},
    };
    this.valid = {};
  }

  public setFormAnswers(data: any) {
    this.data.answers = data;
  }

  public collectedData(): IGameResults {
    return this.data;
  }

  public resetData(): void {
    throw new Error('Method not implemented.');
  }

  public setAnswer(answer: IFormAnswer, valid: boolean) {
    this.data.answers[answer.id] = answer.value;
    this.valid[answer.id] = valid;
  }

  public getAnswerById(id: string): IFormAnswer | null {
    let res: IFormAnswer | null = null;
    if (this.data.answers[id]) {
      res = {
        id: id,
        value: this.data.answers[id],
      };
    }
    return res;
  }

  public getValidById(id: string): boolean | null {
    return this.valid[id] || null;
  }

  public getQuestions(): IFUQuestion[] {
    return this.settings.questions;
  }

  public setAsLastGame(last: boolean) {}
}

export default FormularioUsuarioContent;
