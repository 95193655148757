import { useAppContext } from '../../common/AppContext';
import { AppReducerActionTypes } from '../../common/AppReducer';
import { SaveResults } from '../../common/SaveResults';
import MentirosoGame from './MentirosoGame';
import logo from './../../../assets/images/cierre.gif';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import { ShowModal } from '../../../utils/ShowModal';
import { useState } from 'react';
import ReactGA from 'react-ga4';

const M2_5 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/mentiroso-2/5' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/mentiroso-2/5',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const [disableContinuar, setDisableContinuar] = useState(false);

  const game = appState.currentScreen.game as MentirosoGame;

  ScreenUtils.hideBG();

  function next() {
    setDisableContinuar(true);
    SaveResults(
      game.collectedData(),
      appState.bearer_token,
      () => {
        setDisableContinuar(false);
        pageDispatch({ type: PageScreenReducerActionTypes.RESET });
        appDispatch({ type: AppReducerActionTypes.NEXT });
      },
      (connectionError: boolean, code: string, message: string) => {
        ShowModal(connectionError, appDispatch, code, message);
        setDisableContinuar(false);
      }
    );
  }

  return (
    <>
      <img src={logo} alt='logo' className={'logoSolapa'} />
      <div>
        <p className='sideMargin50'>Alcanzaste el 60% de esta prueba.</p>
        <p className='sideMargin50'>
          En instantes se presentará la última tanda de diamantes.
        </p>
        <p className='sideMargin50'>¡Está atento!</p>
      </div>
      <p></p>

      <div className='bottomButtonsSingleRight'>
        <button
          className='nextButton'
          onClick={next}
          disabled={disableContinuar}
        >
          Continuar
        </button>
      </div>
    </>
  );
};

export default M2_5;
