import { useReducer } from 'react';
import { useAppContext } from '../../common/AppContext';
import Header from '../../common/Header';
import PageFlow from '../../common/PageFlow';
import PageScreenContext from '../../common/PageScreenContext';
import { pageScreenReducer } from '../../common/PageScreenReducer';

import AC_0 from './AC_0';
import AC_1 from './AC_1';
import AC_2 from './AC_2';
import AutocontrolGame from './AutocontrolGame';

let screens: JSX.Element[] = [];

const Autocontrol = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const game = appState.currentScreen.game as AutocontrolGame;
  game.setAsLastGame(appState.currentScreen.last);

  function prepareScreens() {
    screens = [];

    screens.push(<AC_0 />);
    for (let i = 0; i < game.questionAmount(); i++) {
      screens.push(<AC_1 />);
    }

    screens.push(<AC_2 />);
  }

  prepareScreens();

  const initialState = { index: 0, length: screens.length };
  const [pageState, pageDispatch] = useReducer(pageScreenReducer, initialState);

  const providerState = {
    state: pageState,
    dispatch: pageDispatch,
  };

  return (
    <PageScreenContext.Provider value={providerState}>
      <Header>
        <PageFlow children={screens[pageState.index]} />
      </Header>
    </PageScreenContext.Provider>
  );
};

export default Autocontrol;
