import { useState } from 'react';
import { IFormAnswer } from './IFormAnswer';

export interface IFormQuestionEmail {
  id: string;
  question: string;
  onChangeCallback: (data: IFormAnswer, valid: boolean) => void;
  value: string | null;
}

const FormQuestionEmail = (props: IFormQuestionEmail) => {
  let min: number = 6;
  let max: number = 320;

  const [valid, setValid] = useState('');

  function changed(element: any) {
    setValid('');
    let validValue = true;

    if (
      element.target.value.length < min ||
      element.target.value.length > max
    ) {
      setValid('formInputNotValid');
      validValue = false;
    }

    if (!validateEmail(element.target.value)) {
      setValid('formInputNotValid');
      validValue = false;
    }

    const data: IFormAnswer = {
      id: props.id,
      value: element.target.value,
    };
    props.onChangeCallback(data, validValue);
  }

  function validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  return (
    <div className='formQuestionText' key={'formQuestionText' + props.id}>
      <p>{props.question}</p>
      <input
        type='email'
        name={props.id}
        onChange={changed}
        key={'text' + props.id}
        defaultValue={props.value || undefined}
        className={valid}
      />
    </div>
  );
};

export default FormQuestionEmail;
