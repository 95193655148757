import { useEffect, useState } from 'react';
import { useElapsedTime } from 'use-elapsed-time';
import { useAppContext } from '../../common/AppContext';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import Slider from '../../common/Slider';
import AutoeficaciaEconomicaGame from './AutoeficaciaEconomicaGame';
import Dots from '../../common/Dots';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import ReactGA from 'react-ga4';

const AE_2 = () => {
  interface ILocalState {
    [key: string]: string;
  }

  const [localState, setLocalState] = useState<ILocalState>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  let game = appState.currentScreen.game as AutoeficaciaEconomicaGame;

  let elapsedTime = useElapsedTime({
    isPlaying: true,
  });

  ScreenUtils.showBG();

  function next() {
    for (let id in localState) {
      game.setAnswer(id, localState[id], elapsedTime.elapsedTime);
    }

    elapsedTime.reset();
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  let question = game.getQuestion();

  let id: string = question.id;
  let texts: string[] = [];

  question.options.forEach(option => texts.push(option.text));

  function initialSliderValue(id: string, value: string) {
    setLocalState({ [id]: value });
  }

  function sliderOnChange(id: string, value: string) {
    setLocalState({ [id]: value });
    game.setAnswerHistorical(id, value, elapsedTime.elapsedTime);
  }

  let amount = game.questionAmount();
  let current = game.questionIndex();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/autoeficacia-economica/2/' + current,
    });
    ReactGA.event({
      category: 'vista',
      action: 'render',
      label: '/autoeficacia-economica/2/' + current,
      nonInteraction: true,
    });
  }, [current]);

  return (
    <>
      <Dots amount={amount} active={current} key='dots' />

      <p className={'sideMargin30'}>{question.question}</p>

      <Slider
        id={id}
        texts={texts}
        onChange={sliderOnChange}
        initialValue={initialSliderValue}
        steps={5}
      />
      <div className='bottomButtonsSingleRight'>
        <button className='nextButton' onClick={next}>
          Continuar
        </button>
      </div>
    </>
  );
};

export default AE_2;
