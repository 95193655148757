import { IFormAnswer } from './IFormAnswer';
import { IFormOption } from './IFormOption';

export interface IFormQuestionDrop {
  id: string;
  question: string;
  options: IFormOption[];
  onChangeCallback: (data: IFormAnswer, valid: boolean) => void;
  value: string | null;
}

const FormQuestionDrop = (props: IFormQuestionDrop) => {
  function changed(element: any) {
    const data: IFormAnswer = {
      id: props.id,
      value: element.target[element.target.selectedIndex].id,
    };
    props.onChangeCallback(data, true);
  }

  return (
    <div className='formQuestionDrop' key={'formQuestionDrop' + props.id}>
      <p>{props.question}</p>
      <select
        name={props.id}
        onChange={changed}
        key={'select' + props.id}
        defaultValue={props.value || undefined}
      >
        <option id={''} key={'option' + props.id + '-none'}></option>
        {props.options.map((option, index) => (
          <option id={option.id} key={'option' + props.id + '-' + option.id}>
            {option.option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FormQuestionDrop;
