import { IGame } from '../../../interfaces/IGame';
import { IGameResults } from '../../../interfaces/IGameResults';

interface IAnswer {
  value: string;
  elapsedTime: number;
  timedOut: boolean;
  correct: boolean;
}

interface IMResults extends IGameResults {
  answers: {
    [key: string]: { selected: IAnswer };
  };
  score: number;
}

interface IQuestion {
  id: string;
  left: string;
  right: string;
}

interface IQuestions {
  id: string;
  left: number;
  question: number;
  ambiguous: boolean;
}

interface IPrizes {
  left: number;
  right: number;
}

interface IACSettings {
  coins: number;
  questions: IQuestions[];
  prizes: IPrizes;
  answerTimeout: number;
}

export enum MentirosoVariants {
  Bronze,
  Silver,
  Gold,
}

export enum AnswerTypes {
  LEFT = 'left',
  RIGHT = 'right',
  TIMEDOUT = 'timedout',
}

class MentirosoGame implements IGame {
  private data: IMResults;
  private settings: IACSettings;
  private currentQuestion: number;
  private variant: MentirosoVariants;
  private score: number;

  private errorLA: number;
  private errorLNA: number;
  private errorRA: number;
  private errorRNA: number;

  constructor(
    gameId: string,
    settings: IACSettings,
    variant: MentirosoVariants
  ) {
    this.settings = settings;
    this.data = {
      gameId: gameId,
      answers: {},
      score: 0,
    };
    this.currentQuestion = 0;
    this.variant = variant;
    this.score = 0;

    this.errorLA = 0;
    this.errorLNA = 0;
    this.errorRA = 0;
    this.errorRNA = 0;
  }

  public collectedData(): IGameResults {
    this.data.results = JSON.stringify({
      izqAmb: this.errorLA,
      izqNoAmb: this.errorLNA,
      derAmb: this.errorRA,
      derNoAmb: this.errorRNA,
    });
    return this.data;
  }

  public resetData(): void {
    this.data.answers = {};
    this.data.score = 0;
    this.currentQuestion = 0;
    this.score = 0;
  }

  public getQuestion(): IQuestion {
    const question = this.settings.questions[this.currentQuestion];

    return {
      id: question.id,
      left: question.question + '-izq',
      right: question.question + '-der',
    };
  }

  public questionAmount(): number {
    return this.settings.questions.length;
  }

  public gameVariant(): MentirosoVariants {
    return this.variant;
  }

  public questionIndex(): number {
    return this.currentQuestion;
  }

  public getTimeOut(): number {
    return this.settings.answerTimeout;
  }

  public prizes(): IPrizes {
    return this.settings.prizes;
  }

  public getScore(): number {
    return this.score;
  }

  public setAnswer(id: string, response: AnswerTypes, elapsedTime: number) {
    const leftValue = this.settings.questions[this.currentQuestion].left;
    const rightValue = this.settings.coins - leftValue;

    let isCorrect = false;
    switch (response) {
      case AnswerTypes.LEFT:
        isCorrect = leftValue > rightValue;
        if (isCorrect) {
          this.score += this.settings.prizes.left;
        } else {
          if (this.settings.questions[this.currentQuestion].ambiguous) {
            this.errorLA++;
          } else {
            this.errorLNA++;
          }
        }
        break;
      case AnswerTypes.RIGHT:
        isCorrect = rightValue > leftValue;
        if (isCorrect) {
          this.score += this.settings.prizes.right;
        } else {
          if (this.settings.questions[this.currentQuestion].ambiguous) {
            this.errorRA++;
          } else {
            this.errorRNA++;
          }
        }
        break;
    }

    if (isCorrect) {
      this.data.score++;
    }

    let answer: IAnswer = {
      value: response.toString(),
      elapsedTime: elapsedTime,
      timedOut: response === AnswerTypes.TIMEDOUT,
      correct: isCorrect,
    };

    if (this.data.answers[id] === undefined) {
      this.data.answers[id] = {
        selected: answer,
      };
    } else {
      this.data.answers[id].selected = answer;
    }

    this.currentQuestion++;
  }

  public setAsLastGame(last: boolean) {
    this.data.end = last;
  }
}

export default MentirosoGame;
