export class ScreenUtils {
  public static showBG(): void {
    let screenBG = document.getElementById('screenBG');
    if (screenBG !== undefined && screenBG !== null) {
      screenBG.classList.remove('hideScreenBG');
    }
  }

  public static hideBG(): void {
    let screenBG = document.getElementById('screenBG');
    if (screenBG !== undefined && screenBG !== null) {
      screenBG.classList.add('hideScreenBG');
    }
  }

  public static verticalScroll(allow: boolean) {
    let topContainer = document.getElementById('topContainer');
    if (topContainer !== undefined && topContainer !== null) {
      topContainer.style.overflow = allow ? 'auto' : 'hidden';
      topContainer.style.rowGap = allow ? '15px' : 'auto';
    }
  }

  public static showLoading(): void {
    let spinner = document.getElementById('spinner');
    if (spinner !== undefined && spinner !== null) {
      spinner.classList.remove('hideSpinner');
    }
  }

  public static hideLoading(): void {
    let spinner = document.getElementById('spinner');
    if (spinner !== undefined && spinner !== null) {
      spinner.classList.add('hideSpinner');
    }
  }
}
