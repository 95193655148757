import { ScreenUtils } from '../../../utils/ScreenUtils';
import { useAppContext } from '../../common/AppContext';
import { AppReducerActionTypes } from '../../common/AppReducer';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import logo from './../../../assets/images/intro_20minutos.png';
import ReactGA from 'react-ga4';

function IN_1() {
  ReactGA.send({ hitType: 'pageview', page: '/intro/1' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/intro/1',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  function next() {
    pageDispatch({ type: PageScreenReducerActionTypes.RESET });
    appDispatch({ type: AppReducerActionTypes.NEXT });
  }

  ScreenUtils.hideBG();

  return (
    <div className='flexColumnLayout'>
      <p></p>
      <img src={logo} alt='logo' className='logoSolapa' />
      <div>
        <p className='sideMargin50'>
          La entrevista durará 20 minutos aproximadamente. Así que ubicate en un
          lugar cómodo.
        </p>
        <p>¡Comencemos!</p>
      </div>
      <div className='bottomButtonsSingleCenter'>
        <button className='nextButton widerButton' onClick={next}>
          Continuar
        </button>
      </div>
    </div>
  );
}

export default IN_1;
