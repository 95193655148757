import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import Figuras from './Figuras';
import ReactGA from 'react-ga4';

const MTI_2 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/memoria-trabajo-imagenes/2' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/memoria-trabajo-imagenes/2',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();

  function next() {
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  const figuras = Figuras();

  return (
    <>
      <p className={'textM'}>Memorizá el orden de las figuras.</p>

      <div className={'buttonBG flexRowLayout mtiPregunta'}>
        <img src={figuras[6]} alt='' />
        <img src={figuras[7]} alt='' />
        <img src={figuras[8]} alt='' />
      </div>

      <p></p>
      <div className='bottomButtonsSingleRight'>
        <button className='nextButton' onClick={next}>
          Continuar
        </button>
      </div>
    </>
  );
};

export default MTI_2;
