import figura1 from './../../../assets/images/mti/mti_1.png';
import figura2 from './../../../assets/images/mti/mti_2.png';
import figura3 from './../../../assets/images/mti/mti_3.png';
import figura4 from './../../../assets/images/mti/mti_4.png';
import figura5 from './../../../assets/images/mti/mti_5.png';
import figura6 from './../../../assets/images/mti/mti_6.png';
import figura7 from './../../../assets/images/mti/mti_7.png';
import figura8 from './../../../assets/images/mti/mti_8.png';
import figura9 from './../../../assets/images/mti/mti_9.png';
import figura10 from './../../../assets/images/mti/mti_10.png';
import figura11 from './../../../assets/images/mti/mti_11.png';
import figura12 from './../../../assets/images/mti/mti_12.png';

export default function Figuras() {
  return [
    figura1,
    figura2,
    figura3,
    figura4,
    figura5,
    figura6,
    figura7,
    figura8,
    figura9,
    figura10,
    figura11,
    figura12,
  ];
}
