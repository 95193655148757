import { useAppContext } from '../../common/AppContext';
import { AppReducerActionTypes } from '../../common/AppReducer';
import logo from './../../../assets/images/cierre2.gif';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import ReactGA from 'react-ga4';

const MF_3 = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: '/cuestionario-manejo-financiero/3',
  });

  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/cuestionario-manejo-financiero/3',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  ScreenUtils.hideBG();

  function next() {
    pageDispatch({ type: PageScreenReducerActionTypes.RESET });
    appDispatch({ type: AppReducerActionTypes.NEXT });
  }

  return (
    <>
      <img src={logo} alt='logo' className={'logoSolapa'} />
      <p className={'sideMargin50'}>
        Ahora, vamos a continuar con diversas actividades. Algunas de ellas, son
        juegos.
      </p>
      <p className={'sideMargin50'}>
        Es muy importante que prestes
        <span className='textStrong'> atención</span> y respondas con
        <span className='textStrong'> sinceridad y a conciencia.</span>.
      </p>

      <p>¿Continuamos?</p>

      <div className='bottomButtonsSingleRight'>
        <button className='nextButton' onClick={next}>
          Continuar
        </button>
      </div>
    </>
  );
};

export default MF_3;
