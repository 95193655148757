import { IGame } from '../../../interfaces/IGame';
import { IGameResults } from '../../../interfaces/IGameResults';
import { QuestionTypes } from '../../common/FormInputs/QuestionTypes';
import { IFormAnswer } from '../../common/FormInputs/IFormAnswer';
import { IFormOption } from '../../common/FormInputs/IFormOption';

export interface IMFQuestion {
  id: string;
  screen: number;
  question: string;
  options: IFormOption[] | null;
  type: QuestionTypes;
  dependsOnId: string | null;
  ifValue: string | null;
}

interface IMFSettings {
  questions: IMFQuestion[];
  page: number;
}

interface IMFResults extends IGameResults {
  answers: {
    [key: string]: string;
  };
  usuarioAcepto: boolean;
  pagina: number;
  end: boolean;
}

class CuestionarioManejoFinanciero implements IGame {
  private data: IMFResults;
  private settings: IMFSettings;
  private currentScreenIndex: number;
  private valid: {
    [key: string]: boolean;
  };

  constructor(gameId: string, settings: IMFSettings) {
    this.data = {
      gameId: gameId,
      answers: {},
      usuarioAcepto: false,
      pagina: 0,
      end: false,
    };
    this.settings = settings;
    this.currentScreenIndex = this.settings.page;
    this.valid = {};
    this.calculateScreenAmount();
  }

  private screens: number[] = [];
  private calculateScreenAmount() {
    for (const question of this.settings.questions) {
      if (!this.screens.includes(question.screen)) {
        this.screens.push(question.screen);
      }
    }
  }

  public getScreenAmount() {
    return this.screens.length;
  }

  public getScreenIndex() {
    return this.currentScreenIndex;
  }

  public setAccepted() {
    this.data.usuarioAcepto = true;
  }

  public advanceScreen(): boolean {
    this.currentScreenIndex++;
    this.data.end = this.currentScreenIndex >= this.screens.length;
    return this.data.end;
  }

  public undoAdvanceScreen() {
    this.currentScreenIndex--;
    this.data.end = this.currentScreenIndex >= this.screens.length;
  }

  public setAnswer(answer: IFormAnswer, valid: boolean) {
    this.data.answers[answer.id] = answer.value;
    this.valid[answer.id] = valid;
    this.data.pagina = this.currentScreenIndex;
  }

  public removeAnswer(id: string) {
    delete this.data.answers[id];
    delete this.valid[id];
  }

  public getQuestionsForScreen(): IMFQuestion[] {
    return this.settings.questions.filter(
      question => question.screen === this.screens[this.currentScreenIndex]
    );
  }

  public getAnswerById(id: string): IFormAnswer | null {
    let res: IFormAnswer | null = null;
    if (this.data.answers[id]) {
      res = {
        id: id,
        value: this.data.answers[id],
      };
    }
    return res;
  }

  public getValidById(id: string): boolean | null {
    return this.valid[id] || null;
  }

  public collectedData(): IGameResults {
    return this.data;
  }

  public clearPreviousAnswers(): void {
    this.data.answers = {};
    this.valid = {};
  }

  public resetData(): void {
    this.data.answers = {};
    this.data.usuarioAcepto = false;
    this.valid = {};
  }

  public setAsLastGame(last: boolean) {}
}

export default CuestionarioManejoFinanciero;
