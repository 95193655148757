import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import logo from './../../../assets/images/responsabilidadimagenes.gif';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import { useAppContext } from '../../common/AppContext';
import Dots from '../../common/Dots';
import ReactGA from 'react-ga4';

const RI_0 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/responsabilidad-imagenes/0' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/responsabilidad-imagenes/0',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  ScreenUtils.hideBG();

  function next() {
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  return (
    <>
      <Dots
        amount={appState.navigation.gameLength}
        active={appState.navigation.gameCurrent}
        key='dots'
        dash={true}
      />
      <p className={'textStrong textL'}>¿Dónde me acomodo?</p>
      <img src={logo} alt='logo' className={'logoSolapa'} />
      <p className={'sideMargin50'}>
        En esta sección, elegí la imagen que más te representa.
      </p>
      <div className='bottomButtonsSingleCenter'>
        <button className='nextButton' onClick={next}>
          Comenzar
        </button>
      </div>
    </>
  );
};

export default RI_0;
