import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import logo from './../../../assets/images/educacionfinanciera.gif';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import { useAppContext } from '../../common/AppContext';
import Dots from '../../common/Dots';
import ReactGA from 'react-ga4';

const EF_0 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/educacion-financiera/0' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/educacion-financiera/0',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  ScreenUtils.hideBG();

  function next() {
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  return (
    <>
      <Dots
        amount={appState.navigation.gameLength}
        active={appState.navigation.gameCurrent}
        key='dots'
        dash={true}
      />
      <p className={'textStrong textL'}>Expertos en Promociones</p>
      <img src={logo} alt='logo' className={'logoSolapa'} />
      <p className='sideMargin50'>¿Cuánto sabés de economía doméstica?</p>
      <p className='sideMargin50'>
        Te propondremos cuatro situaciones. Elegí la opción que te parezca
        correcta.
      </p>
      <p className='sideMargin50'>
        Tené en cuenta que el tiempo para responder es limitado.
      </p>
      <div className='bottomButtonsSingleCenter'>
        <button className='nextButton' onClick={next}>
          Comenzar
        </button>
      </div>
    </>
  );
};

export default EF_0;
