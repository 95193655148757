import { useEffect, useReducer, useState } from 'react';
import { showApp } from '.';
import AppContext from './components/common/AppContext';
import {
  appReducer,
  AppReducerActionTypes,
  AppState,
} from './components/common/AppReducer';
import { processSettings } from './processSettings';
import axios from 'axios';
import './App.css';

import Modal from 'react-modal';
import ReactGA from 'react-ga4';
import InvisibleImages from './InvisibleImages';
import { ShowModal } from './utils/ShowModal';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

// Modal styles
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0px',
    background: 'rgba(0,0,0,0)',
  },
  overlay: {
    background: 'rgba(0,0,0,0.75)',
  },
};

const App = () => {
  const initialState: AppState = {
    navigation: {
      index: 0,
      length: 1,
      gameCurrent: 0,
      gameLength: 0,
    },
    order: ['intro'],
    screens: {
      intro: {
        component: <div />,
        game: undefined,
        last: false,
      },
    },
    currentScreen: {
      component: <div />,
      game: undefined,
      last: false,
    },
    bearer_token: '',
    showModal: false,
    modalMessage: '',
    modalExtra: null,
    mentirosoScore: 0,
  };

  const [state, dispatch] = useReducer(appReducer, initialState);

  const [activeGames, setActiveGames] = useState(['waitingfordata']);

  const providerState = {
    state,
    dispatch,
  };

  function afterOpenModal() {}

  function closeModal() {
    let URL = process.env.REACT_APP_API_URL + 'ping';

    dispatch({
      type: AppReducerActionTypes.HIDE_MODAL,
    });

    axios
      .get(URL)
      .then(response => {
        console.log(response);
      })
      .catch(reason => {
        console.error(reason);
        ShowModal(reason.request.status === 0, dispatch, reason, reason);
      });
  }

  useEffect(() => {
    ReactGA.initialize('G-7HKZFNHN6W', {
      //testMode: true,
      gaOptions: {
        sampleRate: 100,
        siteSpeedSampleRate: 100,
      },
    });

    ReactGA.event({
      category: 'aplicacion',
      action: 'carga',
      nonInteraction: true,
    });

    const queryParams = new URLSearchParams(window.location.search);
    const token: string = queryParams.get('token') as string;

    let URL = process.env.REACT_APP_API_URL || '';

    if (!URL) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        URL = './server-settings-dummy.json';
      } else {
        console.error('no hay URL definida');
        throw new Error('No hay una URL definida');
      }
    } else {
      URL += token;
    }

    axios
      .get(URL, {
        headers: {
          'app-identification': process.env.REACT_APP_ID,
        },
      })
      .then(serverSettings => {
        let context: AppState = processSettings(serverSettings.data);
        context.bearer_token = serverSettings.headers.authorization;

        let activeGames = serverSettings.data.order;

        if (activeGames.length === 0) {
          activeGames = ['common', 'despedida'];
        } else {
          activeGames.unshift('common');
        }

        setActiveGames(activeGames);

        dispatch({
          type: AppReducerActionTypes.INITIALIZE,
          settings: context,
        });

        showApp();
      })
      .catch(reason => {
        console.error(reason);
        let message = 'Error desconocido';
        let code = '0';

        if (reason.response) {
          if (reason.response.data) {
            code = reason.response.data.statusCode;
            message = reason.response.data.message;

            ShowModal(reason.request.status === 0, dispatch, code, message);
          }
        } else {
          message = reason.message;
          code = reason.request?.status;

          if (!code) {
            code = '2';
          }

          ShowModal(false, dispatch, code, message);
        }
      });
  }, []);

  let modalExtraMessage: string[] = [];

  if (state.modalExtra) {
    /*if (state.modalExtra.response) {
      modalExtraMessage = [
        state.modalExtra.message,
        '[' + state.modalExtra.response.data.statusCode + ']',
        state.modalExtra.response.data.message,
      ];
    } else {
      modalExtraMessage = [state.modalExtra.message];
    }*/
    modalExtraMessage = [
      '[' + state.modalExtra.code + ']',
      state.modalExtra.message,
    ];
  }

  return (
    <div id='screenBG' className='screenBG'>
      <div id='main' className='main'>
        <Modal
          isOpen={state.showModal}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel={state.modalMessage}
        >
          <button onClick={closeModal} className='modalButton'>
            <div className='modalInside'>
              <div className='modalText'>{state.modalMessage}</div>
              <button onClick={closeModal} className='popupButton'>
                Reintentar
              </button>
            </div>
          </button>
          <div className='modalExtra'>
            {modalExtraMessage.map((data, index) => {
              return <p key={'extramessage' + index}>{data}</p>;
            })}
          </div>
        </Modal>
        <AppContext.Provider value={providerState}>
          {state.currentScreen.component}
        </AppContext.Provider>
        <InvisibleImages games={activeGames} />
      </div>
      <div className='modalExtra'>
        {modalExtraMessage.map((data, index) => {
          return <p key={'extramessage' + index}>{data}</p>;
        })}
      </div>
    </div>
  );
};

export default App;
