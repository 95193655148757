import React, { Dispatch } from 'react';
import { AppAction, AppState } from './AppReducer';

interface IAppContextProps {
  state: AppState;
  dispatch: Dispatch<AppAction>;
}

const AppContext = React.createContext({} as IAppContextProps);

export function useAppContext() {
  return React.useContext(AppContext);
}

export default AppContext;
