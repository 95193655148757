// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dots_dot__uSxBg {
  background-color: #c4c4c4;
  border-radius: 50%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 3px;
}

.Dots_activeDot__Iof1y {
  background-color: var(--main-color);
  border-radius: 50%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 3px;
}

.Dots_dash__t-6NA {
  background-color: var(--main-color);
  border-top-left-radius: 3.5px;
  border-bottom-left-radius: 3.5px;
  border-top-right-radius: 3.5px;
  border-bottom-right-radius: 3.5px;
  display: inline-block;
  width: 12px;
  height: 7px;
  margin-right: 2px;
}

.Dots_activeDash__GBlFQ {
  background-color: var(--secondary-color);
  border-top-left-radius: 3.5px;
  border-bottom-left-radius: 3.5px;
  border-top-right-radius: 3.5px;
  border-bottom-right-radius: 3.5px;
  display: inline-block;
  width: 12px;
  height: 7px;
  margin-right: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Dots.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,mCAAmC;EACnC,6BAA6B;EAC7B,gCAAgC;EAChC,8BAA8B;EAC9B,iCAAiC;EACjC,qBAAqB;EACrB,WAAW;EACX,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,wCAAwC;EACxC,6BAA6B;EAC7B,gCAAgC;EAChC,8BAA8B;EAC9B,iCAAiC;EACjC,qBAAqB;EACrB,WAAW;EACX,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".dot {\n  background-color: #c4c4c4;\n  border-radius: 50%;\n  display: inline-block;\n  width: 12px;\n  height: 12px;\n  margin-right: 3px;\n}\n\n.activeDot {\n  background-color: var(--main-color);\n  border-radius: 50%;\n  display: inline-block;\n  width: 12px;\n  height: 12px;\n  margin-right: 3px;\n}\n\n.dash {\n  background-color: var(--main-color);\n  border-top-left-radius: 3.5px;\n  border-bottom-left-radius: 3.5px;\n  border-top-right-radius: 3.5px;\n  border-bottom-right-radius: 3.5px;\n  display: inline-block;\n  width: 12px;\n  height: 7px;\n  margin-right: 2px;\n}\n\n.activeDash {\n  background-color: var(--secondary-color);\n  border-top-left-radius: 3.5px;\n  border-bottom-left-radius: 3.5px;\n  border-top-right-radius: 3.5px;\n  border-bottom-right-radius: 3.5px;\n  display: inline-block;\n  width: 12px;\n  height: 7px;\n  margin-right: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dot": `Dots_dot__uSxBg`,
	"activeDot": `Dots_activeDot__Iof1y`,
	"dash": `Dots_dash__t-6NA`,
	"activeDash": `Dots_activeDash__GBlFQ`
};
export default ___CSS_LOADER_EXPORT___;
