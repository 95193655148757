import { IGame } from '../../../interfaces/IGame';
import { IGameResults } from '../../../interfaces/IGameResults';

interface IAnswer {
  value: string;
  elapsedTime: number;
}

interface IRIResults extends IGameResults {
  answers: {
    [key: string]: { selected: IAnswer; historical: IAnswer[] };
  };
}

interface IOptions {
  id: string;
  text: string;
}

export interface IQuestions {
  id: string;
  question: string;
}

interface IRISettings {
  questions: IQuestions[];
  options: IOptions[];
}

class AutoeficaciaEconomicaGame implements IGame {
  private data: IRIResults;
  private settings: IRISettings;
  private currentQuestion: number;

  constructor(gameId: string, settings: IRISettings) {
    this.settings = settings;
    this.data = {
      gameId: gameId,
      answers: {},
    };
    this.currentQuestion = 0;
  }

  public collectedData(): IGameResults {
    return this.data;
  }

  public resetData(): void {
    this.data.answers = {};
    this.currentQuestion = 0;
  }

  public getQuestion() {
    // TODO agregar al retorno el valor actual de la pregunta si el usuario volvio para atras
    return {
      ...this.settings.questions[this.currentQuestion],
      //id: this.settings.questions[this.currentQuestion].id,
      //question: this.settings.questions[this.currentQuestion].question,
      options: this.settings.options,
    };
  }

  public questionAmount(): number {
    return this.settings.questions.length;
  }

  public questionIndex(): number {
    return this.currentQuestion;
  }

  public setAnswer(id: string, response: string, elapsedTime: number) {
    let answer: IAnswer = {
      value: response,
      elapsedTime: elapsedTime,
    };

    if (this.data.answers[id] === undefined) {
      this.data.answers[id] = {
        selected: answer,
        historical: [],
      };
    } else {
      this.data.answers[id].selected = answer;
    }

    this.currentQuestion++;
  }

  public setAnswerHistorical(
    id: string,
    response: string,
    elapsedTime: number
  ) {
    let answer: IAnswer = {
      value: response,
      elapsedTime: elapsedTime,
    };

    if (this.data.answers[id] === undefined) {
      this.data.answers[id] = {
        selected: { value: '', elapsedTime: 0 },
        historical: [],
      };
    }

    this.data.answers[id].historical.push(answer);
  }

  public setAsLastGame(last: boolean) {
    this.data.end = last;
  }
}

export default AutoeficaciaEconomicaGame;
