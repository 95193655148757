import { IFormAnswer } from './IFormAnswer';
import { IFormOption } from './IFormOption';

export interface IFormQuestionBinary {
  id: string;
  question: string;
  onChangeCallback: (data: IFormAnswer, valid: boolean) => void;
  value: string | null;
  options?: IFormOption[] | null;
}

const FormQuestionBinary = (props: IFormQuestionBinary) => {
  function changed(element: any) {
    const data: IFormAnswer = {
      id: props.id,
      value: element.target.value,
    };
    props.onChangeCallback(data, true);
  }

  const optionComponents: JSX.Element[] = [];

  if (props.options) {
    for (const option of props.options) {
      optionComponents.push(
        <label key={'fqb' + option.id + props.id}>
          <input
            type='radio'
            name={props.id}
            value={option.id}
            onChange={changed}
            key={option.id + props.id}
            data-radio='image'
            checked={props.value === option.id}
          />
          <div data-radio-type='cuestionariomanejofinanciero'>
            {option.option}
          </div>
        </label>
      );
    }
  } else {
    optionComponents.push(
      <label key={'fqbsi' + props.id}>
        <input
          type='radio'
          name={props.id}
          value='si'
          onChange={changed}
          key={'si' + props.id}
          data-radio='image'
          checked={props.value === 'si'}
        />
        <div data-radio-type='cuestionariomanejofinanciero'>Sí</div>
      </label>
    );

    optionComponents.push(
      <label key={'fqbno' + props.id}>
        <input
          type='radio'
          name={props.id}
          value='no'
          onChange={changed}
          key={'no' + props.id}
          data-radio='image'
          checked={props.value === 'no'}
        />
        <div data-radio-type='cuestionariomanejofinanciero'>No</div>
      </label>
    );
  }

  return (
    <div className='formQuestionBinary' key={'formQuestionBinary' + props.id}>
      <p>{props.question}</p>
      <div className='formQuestionBinaryButtonContainer'>
        {optionComponents.map((option, index) => {
          return option;
        })}
      </div>
    </div>
  );
};

export default FormQuestionBinary;
