import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ScreenUtils } from './utils/ScreenUtils';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

declare global {
  interface Window {
    AppReady: {
      ready1: boolean;
      ready2: boolean;
    };
  }
}

export function showApp() {
  window.AppReady = window.AppReady || {
    ready1: false,
    ready2: false,
  };
  window.AppReady.ready1 = true;
  showAppIfReady();
}

export function showApp2() {
  window.AppReady = window.AppReady || {
    ready1: false,
    ready2: false,
  };
  window.AppReady.ready2 = true;
  showAppIfReady();
}

function showAppIfReady() {
  if (!window.AppReady) {
    return;
  }

  if (window.AppReady.ready1 && window.AppReady.ready2) {
    let root = document.getElementById('root');
    if (root !== undefined && root !== null) {
      root.style.visibility = 'visible';
    }

    let main = document.getElementById('main');
    if (main !== undefined && main !== null) {
      main.style.visibility = 'visible';
    }

    document.getElementById('loader')?.remove();
    ScreenUtils.hideLoading();
  }
}
