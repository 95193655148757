import { useAppContext } from '../../common/AppContext';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import logo from './../../../assets/images/memoriatrabajonumeros.gif';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import Dots from '../../common/Dots';
import ReactGA from 'react-ga4';

const MTN_0 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/memoria-trabajo-numeros/0' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/memoria-trabajo-numeros/0',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  ScreenUtils.hideBG();

  function next() {
    appState.currentScreen?.game?.resetData();
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  return (
    <>
      <Dots
        amount={appState.navigation.gameLength}
        active={appState.navigation.gameCurrent}
        key='dots'
        dash={true}
      />
      <div className='sideMargin50'>
        <p className={'textStrong textL'}>Numerofilia</p>
        <img src={logo} alt='logo' className={'logoSolapa'} />
        <p>Visualizarás una secuencia de números. ¡Memorizala bien!</p>
        <p>
          Porque luego deberás escribirla en el mismo orden en que aparecieron.
        </p>
        <p>En cada secuencia la dificultad irá aumentando.</p>
      </div>
      <div className='bottomButtonsSingleCenter'>
        <button className='nextButton' onClick={next}>
          Comenzar
        </button>
      </div>
    </>
  );
};

export default MTN_0;
