import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import ReactGA from 'react-ga4';

const MTI_1 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/memoria-trabajo-imagenes/1' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/memoria-trabajo-imagenes/1',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();

  ScreenUtils.showBG();

  function next() {
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  return (
    <>
      <div className='sideMargin50'>
        <p>Empecemos con un ejemplo.</p>
        <p>¿Vamos?</p>
      </div>
      <div className='bottomButtonsSingleRight'>
        <button className='nextButton' onClick={next}>
          Continuar
        </button>
      </div>
    </>
  );
};

export default MTI_1;
