import { useEffect, useState } from 'react';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import { ShowModal } from '../../../utils/ShowModal';
import { useAppContext } from '../../common/AppContext';
import Dots from '../../common/Dots';
import FormQuestionBinary from '../../common/FormInputs/FormQuestionBinary';
import FormQuestionDate from '../../common/FormInputs/FormQuestionDate';
import FormQuestionDrop from '../../common/FormInputs/FormQuestionDrop';
import FormQuestionNumber from '../../common/FormInputs/FormQuestionNumber';
import FormQuestionText from '../../common/FormInputs/FormQuestionText';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import { SaveResults } from '../../common/SaveResults';
import CuestionarioManejoFinancieroContent, {
  IMFQuestion,
} from './CuestionarioManejoFinancieroContent';
import { IFormAnswer } from '../../common/FormInputs/IFormAnswer';
import { QuestionTypes } from '../../common/FormInputs/QuestionTypes';
import ReactGA from 'react-ga4';

function useForceUpdate() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState(0);
  return () => setValue(value => value + 1);
}

const MF_1 = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const [disableContinuar, setDisableContinuar] = useState(false);

  const game = appState.currentScreen
    .game as CuestionarioManejoFinancieroContent;

  ScreenUtils.verticalScroll(true);
  ScreenUtils.showBG();

  const amount = game.getScreenAmount();
  const current = game.getScreenIndex();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/cuestionario-manejo-financiero/1/' + current,
    });

    ReactGA.event({
      category: 'vista',
      action: 'render',
      label: '/cuestionario-manejo-financiero/1/' + current,
      nonInteraction: true,
    });
  }, [current]);

  function next() {
    const finished = game.advanceScreen();

    const collectedData = game.collectedData();

    // To allow proper redrawing of components
    game.undoAdvanceScreen();

    collectedData.end = finished;

    setDisableContinuar(true);
    SaveResults(
      collectedData,
      appState.bearer_token,
      () => {
        setDisableContinuar(false);
        game.advanceScreen();
        game.clearPreviousAnswers();
        if (finished) {
          ScreenUtils.verticalScroll(false);
          pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
        } else {
          forceUpdate();
        }
      },
      (connectionError: boolean, code: string, message: string) => {
        setDisableContinuar(false);
        ShowModal(connectionError, appDispatch, code, message);
      }
    );
  }

  const questionComponents: JSX.Element[] = [];
  let questions: IMFQuestion[] = game.getQuestionsForScreen();

  let allAnswered: boolean = true;

  for (const question of questions) {
    let visible: boolean = true;

    if (question.dependsOnId) {
      let answer = game.getAnswerById(question.dependsOnId);

      if (question.ifValue?.startsWith('!')) {
        if (!answer || answer.value === question.ifValue.substring(1)) {
          visible = false;
          game.removeAnswer(question.id);
        }
      } else {
        if (!answer || answer.value !== question.ifValue) {
          visible = false;
          game.removeAnswer(question.id);
        }
      }
    }

    if (visible) {
      let qa = game.getAnswerById(question.id);
      if (!qa || qa.value.length === 0) {
        allAnswered = false;
      }

      let valid = game.getValidById(question.id);
      if (!valid) {
        allAnswered = false;
      }

      // Hide commented question
      if (question.question.startsWith('#')) {
        question.question = '';
      }

      switch (question.type) {
        case QuestionTypes.binary:
          questionComponents.push(
            <FormQuestionBinary
              id={question.id}
              question={question.question}
              onChangeCallback={onChangeCallback}
              value={qa?.value || null}
              key={'fqb' + question.id}
            />
          );
          break;
        case QuestionTypes.drop:
          questionComponents.push(
            <FormQuestionDrop
              id={question.id}
              question={question.question}
              options={question.options!}
              onChangeCallback={onChangeCallback}
              value={qa?.value || null}
              key={'fqd' + question.id}
            />
          );
          break;
        case QuestionTypes.text:
          questionComponents.push(
            <FormQuestionText
              id={question.id}
              question={question.question}
              onChangeCallback={onChangeCallback}
              value={qa?.value || null}
              key={'fqt' + question.id}
            />
          );
          break;
        case QuestionTypes.number:
          questionComponents.push(
            <FormQuestionNumber
              id={question.id}
              question={question.question}
              onChangeCallback={onChangeCallback}
              value={qa?.value || null}
              key={'fqt' + question.id}
            />
          );
          break;
        case QuestionTypes.date:
          questionComponents.push(
            <FormQuestionDate
              id={question.id}
              question={question.question}
              options={question.options!}
              onChangeCallback={onChangeCallback}
              value={qa?.value || null}
              key={'fqd' + question.id}
            />
          );
          break;
      }
    }
  }

  const forceUpdate = useForceUpdate();

  function onChangeCallback(data: IFormAnswer, valid: boolean) {
    game.setAnswer(data, valid);
    forceUpdate();
  }

  return (
    <>
      <Dots amount={amount} active={current} key='dots' />

      {questionComponents.map((question, index) => {
        return question;
      })}
      <div className='bottomButtonsSingleRight'>
        <button
          className='nextButton'
          onClick={next}
          key='next'
          disabled={!allAnswered || (allAnswered && disableContinuar)}
        >
          Continuar
        </button>
      </div>
    </>
  );
};

export default MF_1;
