import { IGame } from '../../../interfaces/IGame';
import { IGameResults } from '../../../interfaces/IGameResults';

interface IAnswer {
  value: string;
  elapsedTime: number;
}

interface IDResults extends IGameResults {
  encuesta: IAnswer;
  historical: IAnswer[];
}

class DespedidaContent implements IGame {
  private data: IDResults = {
    gameId: '',
    encuesta: { value: '', elapsedTime: 0 },
    historical: [],
  };

  constructor(gameId: string) {
    this.data = {
      gameId: gameId,
      encuesta: { value: '', elapsedTime: 0 },
      historical: [],
    };
  }

  collectedData(): IGameResults {
    return this.data;
  }

  resetData(): void {
    this.data.encuesta = { value: '', elapsedTime: 0 };
    this.data.historical = [];
  }

  public setAnswer(response: string, elapsedTime: number) {
    this.data.encuesta.value = response;
    this.data.encuesta.elapsedTime = elapsedTime;
  }

  public setAnswerHistorical(response: string, elapsedTime: number) {
    this.data.historical.push({ value: response, elapsedTime: elapsedTime });
  }

  public setAsLastGame(last: boolean) {}
}

export default DespedidaContent;
