import { IScreenContents } from '../../interfaces/IScreenContents';
import { shouldIncreaseCurrentPosition } from '../../processSettings';

export type AppState = {
  navigation: {
    index: number;
    length: number;
    gameCurrent: number;
    gameLength: number;
  };
  order: string[];
  screens: { [name: string]: IScreenContents };
  currentScreen: IScreenContents;
  bearer_token: string;
  showModal: boolean;
  modalMessage: string;
  modalExtra: any;
  mentirosoScore: number;
};

export type AppAction = {
  type: AppReducerActionTypes;
  payload?: number;
  modalMessage?: string;
  modalExtra?: any;
  settings?: AppState;
};

export enum AppReducerActionTypes {
  INITIALIZE,
  NEXT,
  RESET,
  SET,
  SHOW_MODAL,
  HIDE_MODAL,
  ACCUMULATE_MENTIROSO,
}

export const appReducer = (state: AppState, action: AppAction): AppState => {
  let i = state.navigation.index;

  switch (action.type) {
    case AppReducerActionTypes.INITIALIZE:
      if (action.settings !== undefined) {
        return {
          ...state,
          navigation: action.settings.navigation,
          order: action.settings.order,
          screens: action.settings.screens,
          currentScreen: action.settings.currentScreen,
          bearer_token: action.settings.bearer_token,
        };
      }
      break;
    case AppReducerActionTypes.NEXT:
      i += 1;
      if (i >= state.navigation.length) {
        i = 0;
      }
      return {
        ...state,
        navigation: {
          ...state.navigation,
          gameCurrent: shouldIncreaseCurrentPosition(state.order[i])
            ? state.navigation.gameCurrent + 1
            : state.navigation.gameCurrent,
          index: i,
          length: state.navigation.length,
        },
        currentScreen: state.screens[state.order[i]],
      };
    case AppReducerActionTypes.RESET:
      i = 0;
      return {
        ...state,
        navigation: {
          ...state.navigation,
          index: i,
          length: state.navigation.length,
        },
        currentScreen: state.screens[state.order[i]],
      };
    case AppReducerActionTypes.SET:
      if (action.payload !== undefined) {
        i = action.payload;
      }
      return {
        ...state,
        navigation: {
          ...state.navigation,
          index: i,
          length: state.navigation.length,
        },
        currentScreen: state.screens[state.order[i]],
      };
    case AppReducerActionTypes.SHOW_MODAL:
      state.showModal = true;
      state.modalMessage = action.modalMessage!;
      state.modalExtra = action.modalExtra!;
      break;
    case AppReducerActionTypes.HIDE_MODAL:
      state.showModal = false;
      break;
    case AppReducerActionTypes.ACCUMULATE_MENTIROSO:
      state.mentirosoScore += action.payload!;
      break;
    default:
      console.log('unknown action [' + action.type + ']');
  }

  return { ...state };
};
