import React, { Dispatch } from 'react';
import { PageScreenAction, PageScreenState } from './PageScreenReducer';

interface IPageScreenContextProps {
  state: PageScreenState;
  dispatch: Dispatch<PageScreenAction>;
}

const PageScreenContext = React.createContext({} as IPageScreenContextProps);

export function usePageScreenContext() {
  return React.useContext(PageScreenContext);
}

export default PageScreenContext;
