import { AppState } from './components/common/AppReducer';
import CuestionarioManejoFinanciero from './components/contents/CuestionarioManejoFinanciero/CuestionarioManejoFinanciero';
import CuestionarioManejoFinancieroContent from './components/contents/CuestionarioManejoFinanciero/CuestionarioManejoFinancieroContent';
import Despedida from './components/contents/Despedida/Despedida';
import DespedidaContent from './components/contents/Despedida/DespedidaContent';
import Final from './components/contents/Final/Final';
import FormularioUsuario from './components/contents/FormularioUsuario/FormularioUsuario';
import FormularioUsuarioContent from './components/contents/FormularioUsuario/FormularioUsuarioContent';
import Intro from './components/contents/Intro/Intro';
import Autocontrol from './components/games/Autocontrol/Autocontrol';
import AutocontrolGame from './components/games/Autocontrol/AutocontrolGame';
import AutoeficaciaEconomica from './components/games/AutoeficaciaEconomica/AutoeficaciaEconomica';
import AutoeficaciaEconomicaGame from './components/games/AutoeficaciaEconomica/AutoeficaciaEconomicaGame';
import EducacionFinanciera from './components/games/EducacionFinanciera/EducacionFinanciera';
import EducacionFinancieraGame from './components/games/EducacionFinanciera/EducacionFinancieraGame';
import GratificacionRetrasada from './components/games/GratificacionRetrasada/GratificacionRetrasada';
import GratificacionRetrasadaGame from './components/games/GratificacionRetrasada/GratificacionRetrasadaGame';
import MemoriaTrabajoImagenes from './components/games/MemoriaTrabajoImagenes/MemoriaTrabajoImagenes';
import MemoriaTrabajoImagenesGame from './components/games/MemoriaTrabajoImagenes/MemoriaTrabajoImagenesGame';
import MemoriaTrabajoNumeros from './components/games/MemoriaTrabajoNumeros/MemoriaTrabajoNumeros';
import MemoriaTrabajoNumerosGame from './components/games/MemoriaTrabajoNumeros/MemoriaTrabajoNumerosGame';
import Mentiroso from './components/games/Mentiroso/Mentiroso';
import MentirosoGame, {
  MentirosoVariants,
} from './components/games/Mentiroso/MentirosoGame';
import ResponsabilidadImagenes from './components/games/ResponsabilidadImagenes/ResponsabilidadImagenes';
import ResponsabilidadImagenesGame from './components/games/ResponsabilidadImagenes/ResponsabilidadImagenesGame';

/// This should be the only file where there are hardcoded relationships (gameId:string, JSX.Element, IGame)

export function shouldIncreaseCurrentPosition(gameId: string): boolean {
  let result: boolean = true;
  switch (gameId) {
    case 'intro':
    case 'formulario-usuario':
    case 'cuestionario-manejo-financiero':
    case 'despedida':
      result = false;
      break;
  }
  return result;
}

export function processSettings(settings: any): AppState {
  let context: AppState = {
    navigation: {
      index: 0,
      length: settings.order.length,
      gameCurrent: settings.current,
      gameLength: settings.total,
    },
    order: [...settings.order],
    screens: {},
    currentScreen: { component: <div />, game: undefined, last: false },
    bearer_token: '',
    showModal: false,
    modalMessage: '',
    modalExtra: null,
    mentirosoScore: 0,
  };

  let lastGame: string = '';

  for (let i = context.order.length - 1; i >= 0; i--) {
    if (context.order[i] === 'despedida') {
      continue;
    }

    lastGame = context.order[i];
    break;
  }

  context.order.forEach((gameId: string) => {
    let last: boolean = lastGame === gameId;

    switch (gameId) {
      case 'intro':
        context.screens[gameId] = {
          component: <Intro />,
          game: undefined,
          last: last,
        };
        break;
      case 'formulario-usuario':
        context.screens[gameId] = {
          component: <FormularioUsuario />,
          game: new FormularioUsuarioContent(gameId),
          last: last,
        };
        break;
      case 'memoria-trabajo-imagenes':
        context.screens[gameId] = {
          component: <MemoriaTrabajoImagenes />,
          game: new MemoriaTrabajoImagenesGame(
            gameId,
            settings.parameters[gameId]
          ),
          last: last,
        };
        break;
      case 'autocontrol':
        context.screens[gameId] = {
          component: <Autocontrol />,
          game: new AutocontrolGame(gameId, settings.parameters[gameId]),
          last: last,
        };
        break;
      case 'mentiroso-1':
        context.screens[gameId] = {
          component: <Mentiroso />,
          game: new MentirosoGame(
            gameId,
            settings.parameters[gameId],
            MentirosoVariants.Bronze
          ),
          last: last,
        };
        break;
      case 'memoria-trabajo-numeros':
        context.screens[gameId] = {
          component: <MemoriaTrabajoNumeros />,
          game: new MemoriaTrabajoNumerosGame(
            gameId,
            settings.parameters[gameId]
          ),
          last: last,
        };
        break;
      case 'educacion-financiera':
        context.screens[gameId] = {
          component: <EducacionFinanciera />,
          game: new EducacionFinancieraGame(
            gameId,
            settings.parameters[gameId]
          ),
          last: last,
        };
        break;
      case 'mentiroso-2':
        context.screens[gameId] = {
          component: <Mentiroso />,
          game: new MentirosoGame(
            gameId,
            settings.parameters[gameId],
            MentirosoVariants.Silver
          ),
          last: last,
        };
        break;
      case 'gratificacion-retrasada':
        context.screens[gameId] = {
          component: <GratificacionRetrasada />,
          game: new GratificacionRetrasadaGame(
            gameId,
            settings.parameters[gameId]
          ),
          last: last,
        };
        break;
      case 'responsabilidad-imagenes':
        context.screens[gameId] = {
          component: <ResponsabilidadImagenes />,
          game: new ResponsabilidadImagenesGame(
            gameId,
            settings.parameters[gameId]
          ),
          last: last,
        };
        break;
      case 'mentiroso-3':
        context.screens[gameId] = {
          component: <Mentiroso />,
          game: new MentirosoGame(
            gameId,
            settings.parameters[gameId],
            MentirosoVariants.Gold
          ),
          last: last,
        };
        break;
      case 'autoeficacia-economica':
        context.screens[gameId] = {
          component: <AutoeficaciaEconomica />,
          game: new AutoeficaciaEconomicaGame(
            gameId,
            settings.parameters[gameId]
          ),
          last: last,
        };
        break;
      case 'cuestionario-manejo-financiero':
        context.screens[gameId] = {
          component: <CuestionarioManejoFinanciero />,
          game: new CuestionarioManejoFinancieroContent(
            gameId,
            settings.parameters[gameId]
          ),
          last: last,
        };
        break;
      case 'despedida':
        context.screens[gameId] = {
          component: <Despedida />,
          game: new DespedidaContent(gameId),
          last: last,
        };
        break;
      default:
        context.screens[gameId] = {
          component: <Intro />,
          game: undefined,
          last: last,
        };
        break;
    }
  });

  if (context.order.length > 0) {
    context.currentScreen = context.screens[context.order[0]];
  } else {
    context.currentScreen = {
      component: <Final />,
      game: undefined,
      last: false,
    };
  }

  return context;
}
