import { useAppContext } from '../../common/AppContext';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import logo from './../../../assets/images/mentiroso1_2.png';
import ReactGA from 'react-ga4';

const M1_1 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/mentiroso-1/2' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/mentiroso-1/2',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  function next() {
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  return (
    <>
      <p></p>
      <p></p>
      <p></p>
      <div>
        <p className='sideMargin50'>
          Al finalizar el juego, conocerás tu puntaje total, teniendo en cuenta
          tus ACIERTOS y DESACIERTOS.
        </p>
        <img src={logo} alt='logo' className={'logoSolapa'} />
      </div>
      <div className='bottomButtonsSingleRight'>
        <button className='nextButton' onClick={next}>
          Continuar
        </button>
      </div>
    </>
  );
};

export default M1_1;
