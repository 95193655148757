import { useEffect } from 'react';
import { useElapsedTime } from 'use-elapsed-time';
import { useAppContext } from '../../common/AppContext';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import ResponsabilidadImagenesGame from './ResponsabilidadImagenesGame';
import Dots from '../../common/Dots';
import { imagenes } from './Imagenes';
import { textos } from './Imagenes';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import ReactGA from 'react-ga4';

const RI_1 = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  ScreenUtils.showBG();

  let game = appState.currentScreen.game as ResponsabilidadImagenesGame;

  let elapsedTime = useElapsedTime({
    isPlaying: true,
  });

  function nextLeft() {
    let answer = '1';

    if (question.reverse) {
      answer = '5';
    }

    game.setAnswer(id, answer, elapsedTime.elapsedTime);
    elapsedTime.reset();
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  function nextRight() {
    let answer = '5';

    if (question.reverse) {
      answer = '1';
    }

    game.setAnswer(id, answer, elapsedTime.elapsedTime);
    elapsedTime.reset();
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  let question = game.getQuestion();

  let id: string = question.id;

  let amount = game.questionAmount();
  let current = game.questionIndex();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/responsabilidad-imagenes/1/' + current,
    });
    ReactGA.event({
      category: 'vista',
      action: 'render',
      label: '/responsabilidad-imagenes/1/' + current,
      nonInteraction: true,
    });
  }, [current]);

  let figuras: string[] = imagenes(question.situation);
  let texto: string[] = textos(question.situation);

  let left = 0;
  let right = 1;

  if (question.reverse) {
    left = 1;
    right = 0;
  }

  return (
    <>
      <p></p>
      <p></p>
      <Dots amount={amount} active={current} key={'dots' + id} />
      <p></p>
      <p></p>
      <div className='flexColumnLayout'>
        <button className='responsabilidadImagenesButton' onClick={nextLeft}>
          <img
            src={figuras[left]}
            alt={texto[left]}
            style={{
              width: '70%',
            }}
          />
          <p className='text textStrong'>{texto[left]}</p>
        </button>
        <button className='responsabilidadImagenesButton' onClick={nextRight}>
          <img
            src={figuras[right]}
            alt={texto[right]}
            style={{
              width: '70%',
            }}
          />
          <p className='text textStrong'>{texto[right]}</p>
        </button>
      </div>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
    </>
  );
};

export default RI_1;
