import { ScreenUtils } from '../../../utils/ScreenUtils';
import logo from './../../../assets/images/despedida2.png';
import ReactGA from 'react-ga4';

const D_2 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/final/0' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/final/0',
    nonInteraction: true,
  });

  ScreenUtils.hideBG();
  return (
    <>
      <img src={logo} alt='logo' className={'logoSolapa'} />

      <p className={'sideMargin50'}>
        En 24 horas te responderemos si tu préstamo fue aprobado Muchas
      </p>
      <p className={'sideMargin50'}>
        Muchas gracias por participar de la entrevista!
      </p>
      <p></p>
    </>
  );
};

export default D_2;
