import { IFormAnswer } from './IFormAnswer';
import { IFormOption } from './IFormOption';

export interface IFormQuestionDate {
  id: string;
  question: string;
  options: IFormOption[];
  onChangeCallback: (data: IFormAnswer, valid: boolean) => void;
  value: string | null;
}

const FormQuestionDate = (props: IFormQuestionDate) => {
  function changed(element: any) {
    const data: IFormAnswer = {
      id: props.id,
      value: element.target[element.target.selectedIndex].id,
    };
    props.onChangeCallback(data, true);
  }

  const years = [];

  const startingYear = parseInt(props.options[0].option);
  const currentYear = new Date().getFullYear();

  for (let i = currentYear; i >= startingYear; --i) {
    years.push(i);
  }

  return (
    <div className='formQuestionDrop' key={'formQuestionDrop' + props.id}>
      <p>{props.question}</p>
      <select
        name={props.id}
        onChange={changed}
        key={'select' + props.id}
        defaultValue={props.value || undefined}
      >
        <option id={''} key={'option' + props.id + '-none'}></option>
        {years.map((year, index) => (
          <option id={year.toString()} key={'option' + props.id + '-' + year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FormQuestionDate;
