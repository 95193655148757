import { useAppContext } from '../../common/AppContext';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import logo from './../../../assets/images/mentiroso2_1.gif';
import MentirosoGame from './MentirosoGame';
import ReactGA from 'react-ga4';

const M2_1 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/mentiroso-2/1' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/mentiroso-2/1',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const game = appState.currentScreen.game as MentirosoGame;
  const prizes = game.prizes();

  function next() {
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  return (
    <>
      <p className='sideMargin50'>
        La regla de premios por adivinar es la siguiente:
      </p>

      <div className='flexRowLayout'>
        <button className={'unclickable metirosoButton'}>
          <span className='textNormal'>
            Si elegís la caja de la IZQUIERDA, ganarás{' '}
            {new Intl.NumberFormat('es-AR', {
              style: 'currency',
              currency: 'ARS',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(prizes.left)}
          </span>
        </button>

        <button className={'unclickable metirosoButton'}>
          <span className='textNormal'>
            Si elegís la caja de la DERECHA ganarás{' '}
            {new Intl.NumberFormat('es-AR', {
              style: 'currency',
              currency: 'ARS',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(prizes.right)}
          </span>
        </button>
      </div>

      <img src={logo} alt='logo' className={'logoSolapa'} />
      <div className='bottomButtonsSingleRight'>
        <button className='nextButton' onClick={next}>
          Continuar
        </button>
      </div>
    </>
  );
};

export default M2_1;
