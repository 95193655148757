import { useReducer } from 'react';
import Header from '../../common/Header';
import PageFlow from '../../common/PageFlow';
import PageScreenContext from '../../common/PageScreenContext';
import { pageScreenReducer } from '../../common/PageScreenReducer';

import FU_0 from './FU_0';
import FU_1 from './FU_1';

let screens: JSX.Element[] = [];

const FormularioUsuario = () => {
  function prepareScreens() {
    screens = [];

    screens.push(<FU_0 />);
    screens.push(<FU_1 />);
  }

  prepareScreens();

  const initialState = { index: 0, length: screens.length };
  const [pageState, pageDispatch] = useReducer(pageScreenReducer, initialState);

  const providerState = {
    state: pageState,
    dispatch: pageDispatch,
  };

  return (
    <PageScreenContext.Provider value={providerState}>
      <Header>
        <PageFlow children={screens[pageState.index]} />
      </Header>
    </PageScreenContext.Provider>
  );
};

export default FormularioUsuario;
