import { useAppContext } from '../../common/AppContext';
import { AppReducerActionTypes } from '../../common/AppReducer';
import { SaveResults } from '../../common/SaveResults';
import EducacionFinancieraGame from './EducacionFinancieraGame';
import logo from './../../../assets/images/cierre.gif';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import { ShowModal } from '../../../utils/ShowModal';
import { useState } from 'react';
import ReactGA from 'react-ga4';

const EF_4 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/educacion-financiera/4' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/educacion-financiera/4',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const [disableContinuar, setDisableContinuar] = useState(false);

  const game = appState.currentScreen.game as EducacionFinancieraGame;

  ScreenUtils.hideBG();

  function next() {
    setDisableContinuar(true);
    SaveResults(
      game.collectedData(),
      appState.bearer_token,
      () => {
        setDisableContinuar(false);
        pageDispatch({ type: PageScreenReducerActionTypes.RESET });
        appDispatch({ type: AppReducerActionTypes.NEXT });
      },
      (connectionError: boolean, code: string, message: string) => {
        ShowModal(connectionError, appDispatch, code, message);
        setDisableContinuar(false);
      }
    );
  }

  return (
    <>
      <img src={logo} alt='logo' className={'logoSolapa'} />

      <p className={'textXL textStrong'}>¡Bien hecho!</p>
      <p className='sideMargin50'>Continuemos con algo que ya conocés.</p>
      <p></p>
      <p></p>
      <div className='bottomButtonsSingleRight'>
        <button
          className='nextButton'
          onClick={next}
          disabled={disableContinuar}
        >
          Continuar
        </button>
      </div>
    </>
  );
};

export default EF_4;
