import image_i_1_der from './../../../assets/images/mentiroso/i/1-der.png';
import image_i_1_izq from './../../../assets/images/mentiroso/i/1-izq.png';
import image_i_2_der from './../../../assets/images/mentiroso/i/2-der.png';
import image_i_2_izq from './../../../assets/images/mentiroso/i/2-izq.png';
import image_i_3_der from './../../../assets/images/mentiroso/i/3-der.png';
import image_i_3_izq from './../../../assets/images/mentiroso/i/3-izq.png';
import image_i_4_der from './../../../assets/images/mentiroso/i/4-der.png';
import image_i_4_izq from './../../../assets/images/mentiroso/i/4-izq.png';
import image_i_5_der from './../../../assets/images/mentiroso/i/5-der.png';
import image_i_5_izq from './../../../assets/images/mentiroso/i/5-izq.png';
import image_i_6_der from './../../../assets/images/mentiroso/i/6-der.png';
import image_i_6_izq from './../../../assets/images/mentiroso/i/6-izq.png';
import image_i_7_der from './../../../assets/images/mentiroso/i/7-der.png';
import image_i_7_izq from './../../../assets/images/mentiroso/i/7-izq.png';
import image_i_8_der from './../../../assets/images/mentiroso/i/8-der.png';
import image_i_8_izq from './../../../assets/images/mentiroso/i/8-izq.png';
import image_i_9_der from './../../../assets/images/mentiroso/i/9-der.png';
import image_i_9_izq from './../../../assets/images/mentiroso/i/9-izq.png';
import image_i_10_der from './../../../assets/images/mentiroso/i/10-der.png';
import image_i_10_izq from './../../../assets/images/mentiroso/i/10-izq.png';
import image_i_11_der from './../../../assets/images/mentiroso/i/11-der.png';
import image_i_11_izq from './../../../assets/images/mentiroso/i/11-izq.png';
import image_i_12_der from './../../../assets/images/mentiroso/i/12-der.png';
import image_i_12_izq from './../../../assets/images/mentiroso/i/12-izq.png';

import image_ii_1_der from './../../../assets/images/mentiroso/ii/1-der.png';
import image_ii_1_izq from './../../../assets/images/mentiroso/ii/1-izq.png';
import image_ii_2_der from './../../../assets/images/mentiroso/ii/2-der.png';
import image_ii_2_izq from './../../../assets/images/mentiroso/ii/2-izq.png';
import image_ii_3_der from './../../../assets/images/mentiroso/ii/3-der.png';
import image_ii_3_izq from './../../../assets/images/mentiroso/ii/3-izq.png';
import image_ii_4_der from './../../../assets/images/mentiroso/ii/4-der.png';
import image_ii_4_izq from './../../../assets/images/mentiroso/ii/4-izq.png';
import image_ii_5_der from './../../../assets/images/mentiroso/ii/5-der.png';
import image_ii_5_izq from './../../../assets/images/mentiroso/ii/5-izq.png';
import image_ii_6_der from './../../../assets/images/mentiroso/ii/6-der.png';
import image_ii_6_izq from './../../../assets/images/mentiroso/ii/6-izq.png';
import image_ii_7_der from './../../../assets/images/mentiroso/ii/7-der.png';
import image_ii_7_izq from './../../../assets/images/mentiroso/ii/7-izq.png';
import image_ii_8_der from './../../../assets/images/mentiroso/ii/8-der.png';
import image_ii_8_izq from './../../../assets/images/mentiroso/ii/8-izq.png';
import image_ii_9_der from './../../../assets/images/mentiroso/ii/9-der.png';
import image_ii_9_izq from './../../../assets/images/mentiroso/ii/9-izq.png';
import image_ii_10_der from './../../../assets/images/mentiroso/ii/10-der.png';
import image_ii_10_izq from './../../../assets/images/mentiroso/ii/10-izq.png';
import image_ii_11_der from './../../../assets/images/mentiroso/ii/11-der.png';
import image_ii_11_izq from './../../../assets/images/mentiroso/ii/11-izq.png';
import image_ii_12_der from './../../../assets/images/mentiroso/ii/12-der.png';
import image_ii_12_izq from './../../../assets/images/mentiroso/ii/12-izq.png';

import image_iii_1_der from './../../../assets/images/mentiroso/iii/1-der.png';
import image_iii_1_izq from './../../../assets/images/mentiroso/iii/1-izq.png';
import image_iii_2_der from './../../../assets/images/mentiroso/iii/2-der.png';
import image_iii_2_izq from './../../../assets/images/mentiroso/iii/2-izq.png';
import image_iii_3_der from './../../../assets/images/mentiroso/iii/3-der.png';
import image_iii_3_izq from './../../../assets/images/mentiroso/iii/3-izq.png';
import image_iii_4_der from './../../../assets/images/mentiroso/iii/4-der.png';
import image_iii_4_izq from './../../../assets/images/mentiroso/iii/4-izq.png';
import image_iii_5_der from './../../../assets/images/mentiroso/iii/5-der.png';
import image_iii_5_izq from './../../../assets/images/mentiroso/iii/5-izq.png';
import image_iii_6_der from './../../../assets/images/mentiroso/iii/6-der.png';
import image_iii_6_izq from './../../../assets/images/mentiroso/iii/6-izq.png';
import image_iii_7_der from './../../../assets/images/mentiroso/iii/7-der.png';
import image_iii_7_izq from './../../../assets/images/mentiroso/iii/7-izq.png';
import image_iii_8_der from './../../../assets/images/mentiroso/iii/8-der.png';
import image_iii_8_izq from './../../../assets/images/mentiroso/iii/8-izq.png';
import image_iii_9_der from './../../../assets/images/mentiroso/iii/9-der.png';
import image_iii_9_izq from './../../../assets/images/mentiroso/iii/9-izq.png';
import image_iii_10_der from './../../../assets/images/mentiroso/iii/10-der.png';
import image_iii_10_izq from './../../../assets/images/mentiroso/iii/10-izq.png';
import image_iii_11_der from './../../../assets/images/mentiroso/iii/11-der.png';
import image_iii_11_izq from './../../../assets/images/mentiroso/iii/11-izq.png';
import image_iii_12_der from './../../../assets/images/mentiroso/iii/12-der.png';
import image_iii_12_izq from './../../../assets/images/mentiroso/iii/12-izq.png';

import { MentirosoVariants } from './MentirosoGame';

const coinImages: { [index: string]: any } = {
  key_image_i_1_der: image_i_1_der,
  key_image_i_1_izq: image_i_1_izq,
  key_image_i_2_der: image_i_2_der,
  key_image_i_2_izq: image_i_2_izq,
  key_image_i_3_der: image_i_3_der,
  key_image_i_3_izq: image_i_3_izq,
  key_image_i_4_der: image_i_4_der,
  key_image_i_4_izq: image_i_4_izq,
  key_image_i_5_der: image_i_5_der,
  key_image_i_5_izq: image_i_5_izq,
  key_image_i_6_der: image_i_6_der,
  key_image_i_6_izq: image_i_6_izq,
  key_image_i_7_der: image_i_7_der,
  key_image_i_7_izq: image_i_7_izq,
  key_image_i_8_der: image_i_8_der,
  key_image_i_8_izq: image_i_8_izq,
  key_image_i_9_der: image_i_9_der,
  key_image_i_9_izq: image_i_9_izq,
  key_image_i_10_der: image_i_10_der,
  key_image_i_10_izq: image_i_10_izq,
  key_image_i_11_der: image_i_11_der,
  key_image_i_11_izq: image_i_11_izq,
  key_image_i_12_der: image_i_12_der,
  key_image_i_12_izq: image_i_12_izq,

  key_image_ii_1_der: image_ii_1_der,
  key_image_ii_1_izq: image_ii_1_izq,
  key_image_ii_2_der: image_ii_2_der,
  key_image_ii_2_izq: image_ii_2_izq,
  key_image_ii_3_der: image_ii_3_der,
  key_image_ii_3_izq: image_ii_3_izq,
  key_image_ii_4_der: image_ii_4_der,
  key_image_ii_4_izq: image_ii_4_izq,
  key_image_ii_5_der: image_ii_5_der,
  key_image_ii_5_izq: image_ii_5_izq,
  key_image_ii_6_der: image_ii_6_der,
  key_image_ii_6_izq: image_ii_6_izq,
  key_image_ii_7_der: image_ii_7_der,
  key_image_ii_7_izq: image_ii_7_izq,
  key_image_ii_8_der: image_ii_8_der,
  key_image_ii_8_izq: image_ii_8_izq,
  key_image_ii_9_der: image_ii_9_der,
  key_image_ii_9_izq: image_ii_9_izq,
  key_image_ii_10_der: image_ii_10_der,
  key_image_ii_10_izq: image_ii_10_izq,
  key_image_ii_11_der: image_ii_11_der,
  key_image_ii_11_izq: image_ii_11_izq,
  key_image_ii_12_der: image_ii_12_der,
  key_image_ii_12_izq: image_ii_12_izq,

  key_image_iii_1_der: image_iii_1_der,
  key_image_iii_1_izq: image_iii_1_izq,
  key_image_iii_2_der: image_iii_2_der,
  key_image_iii_2_izq: image_iii_2_izq,
  key_image_iii_3_der: image_iii_3_der,
  key_image_iii_3_izq: image_iii_3_izq,
  key_image_iii_4_der: image_iii_4_der,
  key_image_iii_4_izq: image_iii_4_izq,
  key_image_iii_5_der: image_iii_5_der,
  key_image_iii_5_izq: image_iii_5_izq,
  key_image_iii_6_der: image_iii_6_der,
  key_image_iii_6_izq: image_iii_6_izq,
  key_image_iii_7_der: image_iii_7_der,
  key_image_iii_7_izq: image_iii_7_izq,
  key_image_iii_8_der: image_iii_8_der,
  key_image_iii_8_izq: image_iii_8_izq,
  key_image_iii_9_der: image_iii_9_der,
  key_image_iii_9_izq: image_iii_9_izq,
  key_image_iii_10_der: image_iii_10_der,
  key_image_iii_10_izq: image_iii_10_izq,
  key_image_iii_11_der: image_iii_11_der,
  key_image_iii_11_izq: image_iii_11_izq,
  key_image_iii_12_der: image_iii_12_der,
  key_image_iii_12_izq: image_iii_12_izq,
};

export default function getCoinImages(
  name: string,
  gameVariant: MentirosoVariants
) {
  let gameId = '';
  switch (gameVariant) {
    case MentirosoVariants.Bronze:
      gameId = 'i';
      break;
    case MentirosoVariants.Silver:
      gameId = 'ii';
      break;
    case MentirosoVariants.Gold:
      gameId = 'iii';
      break;
  }
  let key = 'key_image_' + gameId + '_' + name.replaceAll('-', '_');
  let r = coinImages[key];

  return r;
}
