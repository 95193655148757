import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import { useAppContext } from '../../common/AppContext';
import Dots from '../../common/Dots';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import MentirosoGame, { AnswerTypes } from './MentirosoGame';
import getCoinImages from './Monedas';
import ReactGA from 'react-ga4';

const M_3 = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  ScreenUtils.showBG();

  const game = appState.currentScreen.game as MentirosoGame;
  const prizes = game.prizes();
  const amount = game.questionAmount();

  let elapsedTime: number;

  ReactGA.send({
    hitType: 'pageview',
    page: '/' + game.collectedData().gameId + '/3/' + game.questionIndex(),
  });

  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/' + game.collectedData().gameId + '/3/' + game.questionIndex(),
    nonInteraction: true,
  });

  const countdownNumber = (remainingTime: any) => {
    elapsedTime = remainingTime.elapsedTime;
    return <div className='timerText'>{remainingTime.remainingTime}</div>;
  };

  function setAnswerAndContinue(answer: AnswerTypes) {
    game.setAnswer(question.id, answer, elapsedTime);
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  const question = game.getQuestion();
  const current = game.questionIndex();
  const answerTimeout = game.getTimeOut();

  function left() {
    setAnswerAndContinue(AnswerTypes.LEFT);
  }

  function right() {
    setAnswerAndContinue(AnswerTypes.RIGHT);
  }

  return (
    <>
      <Dots amount={amount} active={current} key='dots' />
      <CountdownCircleTimer
        key={question.id}
        isPlaying
        duration={answerTimeout}
        colors={[
          ['#004777', 0.33],
          ['#F7B801', 0.33],
          ['#A30000', 0.0],
        ]}
        trailColor={'#ffffff00'}
        size={100}
        onComplete={() => {
          setAnswerAndContinue(AnswerTypes.TIMEDOUT);
        }}
        strokeWidth={10}
        strokeLinecap={'round'}
        children={countdownNumber}
      />

      <p className={'textL textStrong sideMargin50'}>
        ¿Qué caja fuerte tiene más diamantes?
      </p>
      <div className='flexRowLayout'>
        <img
          src={getCoinImages(question.left, game.gameVariant())}
          alt=''
          className={'bolsaMentiroso'}
        />
        <img
          src={getCoinImages(question.right, game.gameVariant())}
          alt=''
          className={'bolsaMentiroso'}
        />
      </div>

      <div className='bottomButtonsDouble'>
        <button className='nextButton' onClick={left}>
          Izquierda <br />
          (+${prizes.left})
        </button>
        <button className='nextButton' onClick={right}>
          Derecha
          <br />
          (+${prizes.right})
        </button>
      </div>
    </>
  );
};

export default M_3;
