import situacion1a from './../../../assets/images/responsabilidadimagenes/Situación 9_Consigo que hagan mis deberes.png';
import situacion1b from './../../../assets/images/responsabilidadimagenes/Situación 9_Hago mis propios deberes.png';

import situacion2a from './../../../assets/images/responsabilidadimagenes/Situación 1_No manejo las cosas con cautela.png';
import situacion2b from './../../../assets/images/responsabilidadimagenes/Situación 1_Manejo las cosas con cautela.png';

import situacion3a from './../../../assets/images/responsabilidadimagenes/Situación 10_Dedico poco tiempo y esfuerzo-en mi trabajo.png';
import situacion3b from './../../../assets/images/responsabilidadimagenes/Situación 10_Dedico mucho tiempo y esfuerzo- a mi trabajo.png';

import situacion4a from './../../../assets/images/responsabilidadimagenes/Situación 11_Me dejo ayudar.png';
import situacion4b from './../../../assets/images/responsabilidadimagenes/Situación 11_Hago todo sola.png';

import situacion5a from './../../../assets/images/responsabilidadimagenes/Situación 3_Desordenado.png';
import situacion5b from './../../../assets/images/responsabilidadimagenes/Situación 3_Ordenado.png';

import situacion6a from './../../../assets/images/responsabilidadimagenes/Situación 12_Logro metas en equipo.png';
import situacion6b from './../../../assets/images/responsabilidadimagenes/Situación 12_Logro metas solo.png';

import situacion7a from './../../../assets/images/responsabilidadimagenes/Situación 5_Dejo mi habitación desordenada.png';
import situacion7b from './../../../assets/images/responsabilidadimagenes/Situación 5_Dejo mi habitación ordenada.png';

import situacion8a from './../../../assets/images/responsabilidadimagenes/Situación 6_No respeto las reglas.png';
import situacion8b from './../../../assets/images/responsabilidadimagenes/Situación 6_Respeto las reglas.png';

import situacion9a from './../../../assets/images/responsabilidadimagenes/Situación 8_No cumplo con mis promesas.png';
import situacion9b from './../../../assets/images/responsabilidadimagenes/Situación 8_Cumplo mis promesas.png';

import situacion10a from './../../../assets/images/responsabilidadimagenes/Situación 2_No tengo nada que aportar.png';
import situacion10b from './../../../assets/images/responsabilidadimagenes/Situación 2_Implemento buenas ideas.png';

import situacion11a from './../../../assets/images/responsabilidadimagenes/Situación 4_No me sale todo perfecto.png';
import situacion11b from './../../../assets/images/responsabilidadimagenes/Situación 4_Quiero que todo sea perfecto.png';

import situacion12a from './../../../assets/images/responsabilidadimagenes/Situación 7_Se me vencen mis cuentas.png';
import situacion12b from './../../../assets/images/responsabilidadimagenes/Situación 7_Pago mis cuentas a tiempo.png';

export function imagenes(question_id: number) {
  switch (question_id) {
    case 1:
      return [situacion1a, situacion1b];
    case 2:
      return [situacion2a, situacion2b];
    case 3:
      return [situacion3a, situacion3b];
    case 4:
      return [situacion4a, situacion4b];
    case 5:
      return [situacion5a, situacion5b];
    case 6:
      return [situacion6a, situacion6b];
    case 7:
      return [situacion7a, situacion7b];
    case 8:
      return [situacion8a, situacion8b];
    case 9:
      return [situacion9a, situacion9b];
    case 10:
      return [situacion10a, situacion10b];
    case 11:
      return [situacion11a, situacion11b];
    case 12:
      return [situacion12a, situacion12b];
  }
  console.error('imagenes no reconocidas');
  return [situacion1a, situacion1b];
}

export function textos(question_id: number) {
  switch (question_id) {
    case 1:
      return [
        'Nada mejor que la ayuda de los amigos',
        'Me esfuerzo en mis tareas',
      ];
    case 2:
      return ['Luego los acomodo', 'Hasta último detalle'];
    case 3:
      return ['Todavía me queda tiempo', 'Termino a horario'];
    case 4:
      return ['Consigo ayuda', 'Me arreglo sola'];
    case 5:
      return ['Se hace lo que se puede', 'Cada cosa en su lugar'];
    case 6:
      return ['Prefiero metas grupales', 'Prefiero mis metas'];
    case 7:
      return ['El orden, puede esperar', 'El orden, en primer lugar'];
    case 8:
      return ['Presto atención al tránsito', 'Respeto las normas'];
    case 9:
      return ['A veces no se puede', 'Siempre cumplo'];
    case 10:
      return ['Situaciones complicadas', 'Encuentro soluciones'];
    case 11:
      return ['El sabor es lo primero', 'La imagen importa'];
    case 12:
      return ['Me ingenio con los pagos', 'Pago a término'];
  }
  console.error('textos imagenes no reconocidas');
  return [situacion1a, situacion1b];
}
