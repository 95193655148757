import { IChildrenProps } from '../../interfaces/IChildrenProps';
import logo from './../../assets/images/logo_credito_argentino_small.png';

const Header = (props: IChildrenProps) => {
  return (
    <div id='topContainer' className='flexColumnLayout'>
      <img src={logo} alt='logo' className='logoHeader' />
      {props.children}
    </div>
  );
};

export default Header;
