import { useReducer } from 'react';
import Header from '../../common/Header';
import PageFlow from '../../common/PageFlow';
import PageScreenContext from '../../common/PageScreenContext';
import { pageScreenReducer } from '../../common/PageScreenReducer';

import IN_0 from './IN_0';
import IN_1 from './IN_1';

let screens: JSX.Element[] = [];

const Intro = () => {
  function prepareScreens() {
    screens = [];

    screens.push(<IN_0 />);
    screens.push(<IN_1 />);
  }

  prepareScreens();

  const initialState = { index: 0, length: screens.length };
  const [pageState, pageDispatch] = useReducer(pageScreenReducer, initialState);

  const providerState = {
    state: pageState,
    dispatch: pageDispatch,
  };

  return (
    <PageScreenContext.Provider value={providerState}>
      <Header>
        <PageFlow children={screens[pageState.index]} />
      </Header>
    </PageScreenContext.Provider>
  );
};

export default Intro;
