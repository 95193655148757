import { useEffect, useState } from 'react';
import { useElapsedTime } from 'use-elapsed-time';
import { useAppContext } from '../../common/AppContext';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import Slider from '../../common/Slider';
import MemoriaTrabajoNumerosGame from './MemoriaTrabajoNumerosGame';
import ReactGA from 'react-ga4';

const MTN_5 = () => {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/memoria-trabajo-numeros/5' });
    ReactGA.event({
      category: 'vista',
      action: 'render',
      label: '/memoria-trabajo-numeros/5',
      nonInteraction: true,
    });
  }, []);

  interface ILocalState {
    [key: string]: string;
  }

  const [localState, setLocalState] = useState<ILocalState>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  let game = appState.currentScreen.game as MemoriaTrabajoNumerosGame;

  let elapsedTime = useElapsedTime({
    isPlaying: true,
  });

  function next() {
    for (let id in localState) {
      game.setPerformance(id, localState[id], elapsedTime.elapsedTime);
    }
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  let id: string = 'performance_post';
  let texts: string[] = [
    'Inferior al promedio',
    'Igual al promedio',
    'Superior al promedio',
  ];

  function initialSliderValue(id: string, value: string) {
    setLocalState({ [id]: value });
  }

  function sliderOnChange(id: string, value: string) {
    setLocalState({ [id]: value });
    game.setPerformanceHistorical(id, value, elapsedTime.elapsedTime);
  }

  return (
    <>
      <p className='sideMargin50'>
        ¿Cómo creés que lo hiciste en comparación con la mayoría de la gente?
      </p>

      <Slider
        id={id}
        texts={texts}
        onChange={sliderOnChange}
        initialValue={initialSliderValue}
        steps={5}
      />

      <div className='bottomButtonsSingleRight'>
        <button className='nextButton' onClick={next}>
          Continuar
        </button>
      </div>
    </>
  );
};

export default MTN_5;
