export type PageScreenState = {
  index: number;
  length: number;
};

export type PageScreenAction = {
  type: PageScreenReducerActionTypes;
  payload?: number;
};

export enum PageScreenReducerActionTypes {
  NEXT,
  RESET,
  SET,
  JUMP,
}

export const pageScreenReducer = (
  state: PageScreenState,
  action: PageScreenAction
) => {
  let i = state.index;

  switch (action.type) {
    case PageScreenReducerActionTypes.NEXT:
      i += 1;
      if (i >= state.length) {
        i = 0;
      }
      break;
    case PageScreenReducerActionTypes.RESET:
      i = 0;
      break;
    case PageScreenReducerActionTypes.SET:
      if (action.payload !== undefined) {
        i = action.payload;
        if (i < 0 || i >= state.length) {
          console.error('set outside boundaries', action.payload);
          i = 0;
        }
      } else {
        console.warn('payload not defined for SET');
      }
      break;
    case PageScreenReducerActionTypes.JUMP:
      if (action.payload !== undefined) {
        i = i + action.payload;

        if (i < 0 || i >= state.length) {
          console.error('jump too long', action.payload);
          i = 0;
        }
      } else {
        console.warn('payload not defined for SET');
      }
      break;
    default:
      console.log('unknown action [' + action.type + ']');
  }

  return { ...state, index: i };
};
