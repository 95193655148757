import { useAppContext } from '../../common/AppContext';
import { AppReducerActionTypes } from '../../common/AppReducer';
import { SaveResults } from '../../common/SaveResults';
import MentirosoGame from './MentirosoGame';
import logo from './../../../assets/images/cierre.gif';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import { ShowModal } from '../../../utils/ShowModal';
import { useState } from 'react';
import ReactGA from 'react-ga4';

const M3_4 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/mentiroso-3/4' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/mentiroso-3/4',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const [disableContinuar, setDisableContinuar] = useState(false);

  const game = appState.currentScreen.game as MentirosoGame;
  const score = game.getScore() + appState.mentirosoScore;

  ScreenUtils.hideBG();

  function next() {
    setDisableContinuar(true);
    appDispatch({
      type: AppReducerActionTypes.ACCUMULATE_MENTIROSO,
      payload: game.getScore(),
    });

    SaveResults(
      game.collectedData(),
      appState.bearer_token,
      () => {
        setDisableContinuar(false);
        pageDispatch({ type: PageScreenReducerActionTypes.RESET });
        appDispatch({ type: AppReducerActionTypes.NEXT });
      },
      (connectionError: boolean, code: string, message: string) => {
        ShowModal(connectionError, appDispatch, code, message);
        setDisableContinuar(false);
      }
    );
  }

  return (
    <>
      <img src={logo} alt='logo' className={'logoSolapa'} />
      <p className={'textStrong textL'}>¡Excelente!</p>
      <div className='sideMargin50'>
        <p>¡Completaste el juego de los diamantes!</p>
        <p>
          Acumulaste:
          <br />
          <span className='textL'>{score} pesos</span>
        </p>
        <p></p>
        <p></p>
      </div>
      <div className='bottomButtonsSingleRight'>
        <button
          className='nextButton'
          onClick={next}
          disabled={disableContinuar}
        >
          Continuar
        </button>
      </div>
    </>
  );
};

export default M3_4;
