import { useAppContext } from '../../common/AppContext';
import { AppReducerActionTypes } from '../../common/AppReducer';
import { SaveResults } from '../../common/SaveResults';
import MemoriaTrabajoNumerosGame from './MemoriaTrabajoNumerosGame';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import logo from './../../../assets/images/cierre.gif';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import { ShowModal } from '../../../utils/ShowModal';
import { useState } from 'react';
import ReactGA from 'react-ga4';

const MTN_6 = () => {
  ReactGA.send({ hitType: 'pageview', page: '/memoria-trabajo-numeros/6' });
  ReactGA.event({
    category: 'vista',
    action: 'render',
    label: '/memoria-trabajo-numeros/6',
    nonInteraction: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const [disableContinuar, setDisableContinuar] = useState(false);

  const game = appState.currentScreen.game as MemoriaTrabajoNumerosGame;

  ScreenUtils.hideBG();

  let congratulations = '';

  switch (game.getScore()) {
    case 0:
      congratulations = '¡Buen trabajo!';
      break;
    case 1:
      congratulations = '¡Bien hecho!';
      break;
    case 2:
      congratulations = '¡Muy buen trabajo!';
      break;
    case 3:
      congratulations = '¡Excelente!';
      break;
    default:
      congratulations = '¡Excelente!';
      break;
  }

  function next() {
    setDisableContinuar(true);
    SaveResults(
      game.collectedData(),
      appState.bearer_token,
      () => {
        setDisableContinuar(false);
        pageDispatch({ type: PageScreenReducerActionTypes.RESET });
        appDispatch({ type: AppReducerActionTypes.NEXT });
      },
      (connectionError: boolean, code: string, message: string) => {
        ShowModal(connectionError, appDispatch, code, message);
        setDisableContinuar(false);
      }
    );
  }

  return (
    <>
      <div>
        <img src={logo} alt='logo' className='logoSolapa' />
        <p className={'textStrong textL'}>{congratulations}</p>
      </div>
      <p></p>
      <div className='bottomButtonsSingleRight'>
        <button
          className='nextButton'
          onClick={next}
          disabled={disableContinuar}
        >
          Continuar
        </button>
      </div>
    </>
  );
};

export default MTN_6;
