import { useAppContext } from '../../common/AppContext';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import AutoeficaciaEconomicaGame from './AutoeficaciaEconomicaGame';
import Slider from '../../common/Slider';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

const AE_1 = () => {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/autoeficacia-economica/1' });
    ReactGA.event({
      category: 'vista',
      action: 'render',
      label: '/autoeficacia-economica/1',
      nonInteraction: true,
    });
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  function next() {
    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  let game = appState.currentScreen.game as AutoeficaciaEconomicaGame;

  let question = game.getQuestion();

  let texts: string[] = [];

  question.options.forEach(option => {
    texts.push(option.text);
  });

  return (
    <>
      <p className={'sideMargin50'}>
        Mové el deslizador para indicar si tenés total seguridad de no poder
        realizar la actividad o si tenés total seguridad de poder realizarla.
      </p>{' '}
      <p className={'sideMargin50'}>
        Podés dejar el deslizador en puntos intermedios.
      </p>
      <p className={'sideMargin50'}>¿Comenzamos?</p>
      <Slider
        id={'slider'}
        texts={texts}
        onChange={() => {}}
        initialValue={() => {}}
        readOnly={true}
        steps={5}
      />
      <div className='bottomButtonsSingleRight'>
        <button className='nextButton' onClick={next}>
          Continuar
        </button>
      </div>
    </>
  );
};

export default AE_1;
