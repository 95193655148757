import { useReducer } from 'react';
import Header from '../../common/Header';
import PageFlow from '../../common/PageFlow';
import PageScreenContext from '../../common/PageScreenContext';
import { pageScreenReducer } from '../../common/PageScreenReducer';

import D_0 from './D_0';
import D_1 from './D_1';
import D_2 from './D_2';

let screens: JSX.Element[] = [];

const Despedida = () => {
  function prepareScreens() {
    screens = [];

    screens.push(<D_0 />);
    screens.push(<D_1 />);
    screens.push(<D_2 />);
  }

  prepareScreens();

  const initialState = { index: 0, length: screens.length };
  const [pageState, pageDispatch] = useReducer(pageScreenReducer, initialState);

  const providerState = {
    state: pageState,
    dispatch: pageDispatch,
  };

  return (
    <PageScreenContext.Provider value={providerState}>
      <Header>
        <PageFlow children={screens[pageState.index]} />
      </Header>
    </PageScreenContext.Provider>
  );
};

export default Despedida;
