import { useState } from 'react';
import { IFormAnswer } from './IFormAnswer';

export interface IFormQuestionNumber {
  id: string;
  question: string;
  onChangeCallback: (data: IFormAnswer, valid: boolean) => void;
  value: string | null;
  min?: number | null;
  max?: number | null;
}

const FormQuestionNumber = (props: IFormQuestionNumber) => {
  let min: number = props.min || 1000;
  let max: number = props.max || 1000000;

  const [valid, setValid] = useState('');

  function changed(element: any) {
    setValid('');
    let validValue = true;
    if (element.target.value < min || element.target.value > max) {
      setValid('formInputNotValid');
      validValue = false;
    }
    const data: IFormAnswer = {
      id: props.id,
      value: element.target.value,
    };
    props.onChangeCallback(data, validValue);
  }

  return (
    <div className='formQuestionText' key={'formQuestionNumber' + props.id}>
      <p>{props.question}</p>
      <input
        type='number'
        name={props.id}
        onChange={changed}
        key={'text' + props.id}
        defaultValue={props.value || undefined}
        min={min}
        max={max}
        className={valid}
      />
    </div>
  );
};

export default FormQuestionNumber;
