import { useReducer } from 'react';
import { useAppContext } from '../../common/AppContext';
import Header from '../../common/Header';
import PageFlow from '../../common/PageFlow';
import PageScreenContext from '../../common/PageScreenContext';
import { pageScreenReducer } from '../../common/PageScreenReducer';
import MemoriaTrabajoNumerosGame from './MemoriaTrabajoNumerosGame';
import MTN_0 from './MTN_0';
import MTN_1 from './MTN_1';
import MTN_2 from './MTN_2';
import MTN_3 from './MTN_3';
import MTN_4 from './MTN_4';
import MTN_5 from './MTN_5';
import MTN_6 from './MTN_6';

let screens: JSX.Element[] = [];

const MemoriaTrabajoNumeros = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const game = appState.currentScreen.game as MemoriaTrabajoNumerosGame;
  game.setAsLastGame(appState.currentScreen.last);

  function prepareScreens() {
    screens = [];

    screens.push(<MTN_0 />);
    screens.push(<MTN_1 />);
    screens.push(<MTN_2 />);
    screens.push(<MTN_3 />);
    screens.push(<MTN_4 />);
    screens.push(<MTN_5 />);
    screens.push(<MTN_6 />);

    if (game.hasTutorial()) {
    }
  }
  prepareScreens();

  const initialState = { index: 0, length: screens.length };
  const [pageState, pageDispatch] = useReducer(pageScreenReducer, initialState);

  const providerState = {
    state: pageState,
    dispatch: pageDispatch,
  };

  return (
    <Header>
      <PageScreenContext.Provider value={providerState}>
        <PageFlow children={screens[pageState.index]} />
      </PageScreenContext.Provider>
    </Header>
  );
};

export default MemoriaTrabajoNumeros;
