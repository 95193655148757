import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useAppContext } from '../../common/AppContext';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import GratificacionRetrasadaGame from './GratificacionRetrasadaGame';

import calendarHoy from './../../../assets/images/gratificacionretrasada/calendarhoy.svg';
import calendar6 from './../../../assets/images/gratificacionretrasada/calendar6.svg';
import calendar3 from './../../../assets/images/gratificacionretrasada/calendar3.svg';
import calendar12 from './../../../assets/images/gratificacionretrasada/calendar12.svg';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import ReactGA from 'react-ga4';

const GR_2 = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: appState, dispatch: appDispatch } = useAppContext();

  const game = appState.currentScreen.game as GratificacionRetrasadaGame;

  const [radioValue, setRadioValue] = useState('');

  let elapsedTime: number;

  ScreenUtils.showBG();

  let stage = game.questionStage();
  let current = game.questionIndex();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/gratificacion-retrasada/2/' + stage + '/' + current,
    });
    ReactGA.event({
      category: 'vista',
      action: 'render',
      label: '/gratificacion-retrasada/2/' + stage + '/' + current,
      nonInteraction: true,
    });
  }, [stage, current]);

  const countdownNumber = (remainingTime: any) => {
    elapsedTime = remainingTime.elapsedTime;
    return <div className='timerText'>{remainingTime.remainingTime}</div>;
  };

  function onChanged(event: ChangeEvent<HTMLInputElement>) {
    // console.log('historical: ' + question.id + ' => ' + event.target.value);
    game.setAnswerHistorical(question.id, event.target.value, elapsedTime);
    setRadioValue(event.target.value);
  }

  function timedOut() {
    game.setTimedOut();
    const form: HTMLFormElement = document.getElementById(
      'grForm'
    ) as HTMLFormElement;
    if (form !== undefined) {
      processForm(form);
    }
  }

  function next(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    processForm(event.target as HTMLFormElement);
    // console.log(event.target);
    // console.log(event.currentTarget);
  }

  function processForm(form: HTMLFormElement) {
    const formData = new FormData(form);
    const formDictionary = Object.fromEntries(formData.entries());
    // const ble = Array.from(formData.entries());
    // console.log(formDictionary);
    // console.log(ble);

    let id: string = formDictionary.id.toString();
    let seleccion: string = formDictionary.frase?.toString();

    if (seleccion === undefined) {
      seleccion = 'sin seleccion';
    }

    game.setAnswer(id, seleccion, elapsedTime);

    setRadioValue('');

    pageDispatch({ type: PageScreenReducerActionTypes.NEXT });
  }

  const question = game.getQuestion();

  const titulos: string[] =
    game.questionIndex() === 0
      ? ['', '¿Qué opción preferís?']
      : ['', 'La oferta ha cambiado'];

  const answerTimeout = game.getTimeOut();

  interface IRadio {
    name: string;
    value: string;
  }

  let radios: IRadio[] = [];

  let calendarIcons = [calendarHoy];

  switch (game.questionStage()) {
    case 0:
      calendarIcons.push(calendar6);
      break;
    case 1:
      calendarIcons.push(calendar3);
      break;
    case 2:
      calendarIcons.push(calendar12);
      break;
    default:
      console.error('stage invalido');
      calendarIcons.push(calendarHoy);
      break;
  }

  question.options.forEach(option =>
    radios.push({
      name: new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(parseInt(option.text)),
      value: option.id,
    })
  );

  return (
    <>
      <p></p>
      {titulos.map((titulo, index) => {
        return (
          <p className={'textStrong textL'} key={'t' + index}>
            &nbsp;{titulo}
          </p>
        );
      })}
      <p></p>
      <CountdownCircleTimer
        key={question.id + 'TIMER'}
        isPlaying
        duration={answerTimeout}
        colors={[
          ['#004777', 0.33],
          ['#F7B801', 0.33],
          ['#A30000', 0.0],
        ]}
        trailColor={'#ffffff00'}
        size={75}
        onComplete={() => {
          timedOut();
        }}
        strokeWidth={5}
        strokeLinecap={'round'}
        children={countdownNumber}
      ></CountdownCircleTimer>
      <p></p>
      <form
        onSubmit={next}
        key='formulariousuario'
        id='grForm'
        className='flexColumnLayout'
      >
        <input type='hidden' name='id' value={question.id} />
        <div className={'flexRowLayoutSA'}>
          {radios.map((radio, index) => (
            <div className='calendarDiv flexColumnLayout' key={'dv2' + index}>
              <img src={calendarIcons[index]} alt='' key={'img' + index} />
              <p></p>
              <label>
                <input
                  key={'tg' + index}
                  id={`radio-${index}`}
                  type='radio'
                  name='frase'
                  value={radio.value}
                  checked={radioValue === radio.value}
                  onChange={onChanged}
                  data-radio='image'
                />
                <div
                  data-radio-type='gratificacionretrasada'
                  className='flexRowLayoutSA'
                >
                  <span>{radio.name}</span>
                </div>
              </label>
            </div>
          ))}
        </div>

        <div className='bottomButtonsSingleRight'>
          <button className='nextButton' disabled={radioValue.length === 0}>
            Continuar
          </button>
        </div>
      </form>
    </>
  );
};

export default GR_2;
