// common
import img28 from './assets/images/logo_credito_argentino_small.png';
import img0 from './assets/images/alertbg.png';
import img3 from './assets/images/cierre.gif';
import img4 from './assets/images/cierre2.gif';

// intro
import img27 from './assets/images/intro_hola.png';
import img26 from './assets/images/intro_20minutos.png';

// formulario-usuario
import img19 from './assets/images/formulariousuario.png';

// cuestionario-manejo-financiero
import img18 from './assets/images/entrevista.png';

// memoria-trabajo-imagenes
import img29 from './assets/images/memoriatrabajoimagenes.gif';
import img112 from './assets/images/mti/mti_1.png';
import img113 from './assets/images/mti/mti_10.png';
import img114 from './assets/images/mti/mti_11.png';
import img115 from './assets/images/mti/mti_12.png';
import img116 from './assets/images/mti/mti_2.png';
import img117 from './assets/images/mti/mti_3.png';
import img118 from './assets/images/mti/mti_4.png';
import img119 from './assets/images/mti/mti_5.png';
import img120 from './assets/images/mti/mti_6.png';
import img121 from './assets/images/mti/mti_7.png';
import img122 from './assets/images/mti/mti_8.png';
import img123 from './assets/images/mti/mti_9.png';

// autocontrol
import img1 from './assets/images/autocontrol.png';

// mentiroso-1
import img105 from './assets/images/mentiroso1.gif';
import img106 from './assets/images/mentiroso1_1.gif';
import img107 from './assets/images/mentiroso1_2.png';
import img33 from './assets/images/mentiroso/i/1-der.png';
import img34 from './assets/images/mentiroso/i/1-izq.png';
import img35 from './assets/images/mentiroso/i/10-der.png';
import img36 from './assets/images/mentiroso/i/10-izq.png';
import img37 from './assets/images/mentiroso/i/11-der.png';
import img38 from './assets/images/mentiroso/i/11-izq.png';
import img39 from './assets/images/mentiroso/i/12-der.png';
import img40 from './assets/images/mentiroso/i/12-izq.png';
import img41 from './assets/images/mentiroso/i/2-der.png';
import img42 from './assets/images/mentiroso/i/2-izq.png';
import img43 from './assets/images/mentiroso/i/3-der.png';
import img44 from './assets/images/mentiroso/i/3-izq.png';
import img45 from './assets/images/mentiroso/i/4-der.png';
import img46 from './assets/images/mentiroso/i/4-izq.png';
import img47 from './assets/images/mentiroso/i/5-der.png';
import img48 from './assets/images/mentiroso/i/5-izq.png';
import img49 from './assets/images/mentiroso/i/6-der.png';
import img50 from './assets/images/mentiroso/i/6-izq.png';
import img51 from './assets/images/mentiroso/i/7-der.png';
import img52 from './assets/images/mentiroso/i/7-izq.png';
import img53 from './assets/images/mentiroso/i/8-der.png';
import img54 from './assets/images/mentiroso/i/8-izq.png';
import img55 from './assets/images/mentiroso/i/9-der.png';
import img56 from './assets/images/mentiroso/i/9-izq.png';

// memoria-trabajo-numeros
import img31 from './assets/images/memoriatrabajonumeros.gif';
import img32 from './assets/images/memoriatrabajonumeros.png';
import img124 from './assets/images/mtn_linea.png';
import img17 from './assets/images/enter.png';
import img5 from './assets/images/delete.png';

// educacion-financiera
import img16 from './assets/images/educacionfinanciera.gif';

// mentiroso-2
import img108 from './assets/images/mentiroso2.gif';
import img109 from './assets/images/mentiroso2_1.gif';
import img57 from './assets/images/mentiroso/ii/1-der.png';
import img58 from './assets/images/mentiroso/ii/1-izq.png';
import img59 from './assets/images/mentiroso/ii/10-der.png';
import img60 from './assets/images/mentiroso/ii/10-izq.png';
import img61 from './assets/images/mentiroso/ii/11-der.png';
import img62 from './assets/images/mentiroso/ii/11-izq.png';
import img63 from './assets/images/mentiroso/ii/12-der.png';
import img64 from './assets/images/mentiroso/ii/12-izq.png';
import img65 from './assets/images/mentiroso/ii/2-der.png';
import img66 from './assets/images/mentiroso/ii/2-izq.png';
import img67 from './assets/images/mentiroso/ii/3-der.png';
import img68 from './assets/images/mentiroso/ii/3-izq.png';
import img69 from './assets/images/mentiroso/ii/4-der.png';
import img70 from './assets/images/mentiroso/ii/4-izq.png';
import img71 from './assets/images/mentiroso/ii/5-der.png';
import img72 from './assets/images/mentiroso/ii/5-izq.png';
import img73 from './assets/images/mentiroso/ii/6-der.png';
import img74 from './assets/images/mentiroso/ii/6-izq.png';
import img75 from './assets/images/mentiroso/ii/7-der.png';
import img76 from './assets/images/mentiroso/ii/7-izq.png';
import img77 from './assets/images/mentiroso/ii/8-der.png';
import img78 from './assets/images/mentiroso/ii/8-izq.png';
import img79 from './assets/images/mentiroso/ii/9-der.png';
import img80 from './assets/images/mentiroso/ii/9-izq.png';

// gratificacion-retrasada
import img20 from './assets/images/gratificacionretrasada/calendar12.svg';
import img21 from './assets/images/gratificacionretrasada/calendar3.svg';
import img22 from './assets/images/gratificacionretrasada/calendar6.svg';
import img23 from './assets/images/gratificacionretrasada/calendarhoy.svg';
import img24 from './assets/images/gratificacionretrasada.gif';

// responsabilidad-imagenes
import img149 from './assets/images/responsabilidadimagenes.gif';
import img125 from './assets/images/responsabilidadimagenes/Situación 10_Dedico mucho tiempo y esfuerzo- a mi trabajo.png';
import img126 from './assets/images/responsabilidadimagenes/Situación 10_Dedico poco tiempo y esfuerzo-en mi trabajo.png';
import img127 from './assets/images/responsabilidadimagenes/Situación 11_Hago todo sola.png';
import img128 from './assets/images/responsabilidadimagenes/Situación 11_Me dejo ayudar.png';
import img129 from './assets/images/responsabilidadimagenes/Situación 12_Logro metas en equipo.png';
import img130 from './assets/images/responsabilidadimagenes/Situación 12_Logro metas solo.png';
import img131 from './assets/images/responsabilidadimagenes/Situación 1_Manejo las cosas con cautela.png';
import img132 from './assets/images/responsabilidadimagenes/Situación 1_No manejo las cosas con cautela.png';
import img133 from './assets/images/responsabilidadimagenes/Situación 2_Implemento buenas ideas.png';
import img134 from './assets/images/responsabilidadimagenes/Situación 2_No tengo nada que aportar.png';
import img135 from './assets/images/responsabilidadimagenes/Situación 3_Desordenado.png';
import img136 from './assets/images/responsabilidadimagenes/Situación 3_Ordenado.png';
import img137 from './assets/images/responsabilidadimagenes/Situación 4_No me sale todo perfecto.png';
import img138 from './assets/images/responsabilidadimagenes/Situación 4_Quiero que todo sea perfecto.png';
import img139 from './assets/images/responsabilidadimagenes/Situación 5_Dejo mi habitación desordenada.png';
import img140 from './assets/images/responsabilidadimagenes/Situación 5_Dejo mi habitación ordenada.png';
import img141 from './assets/images/responsabilidadimagenes/Situación 6_No respeto las reglas.png';
import img142 from './assets/images/responsabilidadimagenes/Situación 6_Respeto las reglas.png';
import img143 from './assets/images/responsabilidadimagenes/Situación 7_Pago mis cuentas a tiempo.png';
import img144 from './assets/images/responsabilidadimagenes/Situación 7_Se me vencen mis cuentas.png';
import img145 from './assets/images/responsabilidadimagenes/Situación 8_Cumplo mis promesas.png';
import img146 from './assets/images/responsabilidadimagenes/Situación 8_No cumplo con mis promesas.png';
import img147 from './assets/images/responsabilidadimagenes/Situación 9_Consigo que hagan mis deberes.png';
import img148 from './assets/images/responsabilidadimagenes/Situación 9_Hago mis propios deberes.png';

// mentiroso-3
import img110 from './assets/images/mentiroso3.gif';
import img111 from './assets/images/mentiroso3_1.gif';
import img81 from './assets/images/mentiroso/iii/1-der.png';
import img82 from './assets/images/mentiroso/iii/1-izq.png';
import img83 from './assets/images/mentiroso/iii/10-der.png';
import img84 from './assets/images/mentiroso/iii/10-izq.png';
import img85 from './assets/images/mentiroso/iii/11-der.png';
import img86 from './assets/images/mentiroso/iii/11-izq.png';
import img87 from './assets/images/mentiroso/iii/12-der.png';
import img88 from './assets/images/mentiroso/iii/12-izq.png';
import img89 from './assets/images/mentiroso/iii/2-der.png';
import img90 from './assets/images/mentiroso/iii/2-izq.png';
import img91 from './assets/images/mentiroso/iii/3-der.png';
import img92 from './assets/images/mentiroso/iii/3-izq.png';
import img93 from './assets/images/mentiroso/iii/4-der.png';
import img94 from './assets/images/mentiroso/iii/4-izq.png';
import img95 from './assets/images/mentiroso/iii/5-der.png';
import img96 from './assets/images/mentiroso/iii/5-izq.png';
import img97 from './assets/images/mentiroso/iii/6-der.png';
import img98 from './assets/images/mentiroso/iii/6-izq.png';
import img99 from './assets/images/mentiroso/iii/7-der.png';
import img100 from './assets/images/mentiroso/iii/7-izq.png';
import img101 from './assets/images/mentiroso/iii/8-der.png';
import img102 from './assets/images/mentiroso/iii/8-izq.png';
import img103 from './assets/images/mentiroso/iii/9-der.png';
import img104 from './assets/images/mentiroso/iii/9-izq.png';

// autoeficacia-economica
import img2 from './assets/images/autoeficaciaeconomica.gif';

// despedida
import img6 from './assets/images/despedida.gif';
import img7 from './assets/images/despedida2.png';
import img8 from './assets/images/despedidaencuesta1.png';
import img9 from './assets/images/despedidaencuesta2.png';
import img10 from './assets/images/despedidaencuesta3.png';
import img11 from './assets/images/despedidaencuesta4.png';
import img12 from './assets/images/despedidaencuesta5.png';
import img13 from './assets/images/despedidaencuesta6.png';
import img14 from './assets/images/despedidaencuesta7.png';
import img15 from './assets/images/despedidaencuesta8.png';

import { useState } from 'react';
import { showApp2 } from '.';

interface Games {
  games: string[];
}

interface LoadingProgress {
  [name: string]: number;
}

interface LoadingFiles {
  [name: string]: string[];
}

const InvisibleImages = (props: Games) => {
  const defaultGames: string[] = [];
  const [activeGames, setActiveGames] = useState(defaultGames);
  const [currentGame, setCurrentGame] = useState('');

  const defaultImages: string[] = [];
  const [images, setImages] = useState(defaultImages);

  const defaultLoadingProgress: LoadingProgress = {
    common: 0,
    intro: 0,
    'formulario-usuario': 0,
    'cuestionario-manejo-financiero': 0,
    'memoria-trabajo-imagenes': 0,
    autocontrol: 0,
    'mentiroso-1': 0,
    'memoria-trabajo-numeros': 0,
    'educacion-financiera': 0,
    'mentiroso-2': 0,
    'gratificacion-retrasada': 0,
    'responsabilidad-imagenes': 0,
    'mentiroso-3': 0,
    'autoeficacia-economica': 0,
    despedida: 0,
  };
  const [loadingProgress, setLoadingProgress] = useState(
    defaultLoadingProgress
  );

  const [firstImagesReady, setFirstImagesReady] = useState(false);

  function loaded() {
    let loaded = loadingProgress;
    loaded[currentGame]++;

    setLoadingProgress(loaded);
    setCurrentGame('');
  }

  const loadingFiles: LoadingFiles = {
    common: [img28, img0, img3, img4],
    intro: [img27, img26],
    'formulario-usuario': [img19],
    'cuestionario-manejo-financiero': [img18],
    'memoria-trabajo-imagenes': [
      img29,
      img112,
      img113,
      img114,
      img115,
      img116,
      img117,
      img118,
      img119,
      img120,
      img121,
      img122,
      img123,
    ],
    autocontrol: [img1],
    'mentiroso-1': [
      img105,
      img106,
      img107,
      img33,
      img34,
      img35,
      img36,
      img37,
      img38,
      img39,
      img40,
      img41,
      img42,
      img43,
      img44,
      img45,
      img46,
      img47,
      img48,
      img49,
      img50,
      img51,
      img52,
      img53,
      img54,
      img55,
      img56,
    ],
    'memoria-trabajo-numeros': [img31, img32, img124, img17, img5],
    'educacion-financiera': [img16],
    'mentiroso-2': [
      img108,
      img109,
      img57,
      img58,
      img59,
      img60,
      img61,
      img62,
      img63,
      img64,
      img65,
      img66,
      img67,
      img68,
      img69,
      img70,
      img71,
      img72,
      img73,
      img74,
      img75,
      img76,
      img77,
      img78,
      img79,
      img80,
    ],
    'gratificacion-retrasada': [img20, img21, img22, img23, img24],
    'responsabilidad-imagenes': [
      img149,
      img125,
      img126,
      img127,
      img128,
      img129,
      img130,
      img131,
      img132,
      img133,
      img134,
      img135,
      img136,
      img137,
      img138,
      img139,
      img140,
      img141,
      img142,
      img143,
      img144,
      img145,
      img146,
      img147,
      img148,
    ],
    'mentiroso-3': [
      img110,
      img111,
      img81,
      img82,
      img83,
      img84,
      img85,
      img86,
      img87,
      img88,
      img89,
      img90,
      img91,
      img92,
      img93,
      img94,
      img95,
      img96,
      img97,
      img98,
      img99,
      img100,
      img101,
      img102,
      img103,
      img104,
    ],
    'autoeficacia-economica': [img2],
    despedida: [
      img6,
      img7,
      img8,
      img9,
      img10,
      img11,
      img12,
      img13,
      img14,
      img15,
    ],
  };

  let contents: string[] = [];

  if (props) {
    if (props.games.length > 0) {
      if (props.games[0] === 'waitingfordata') {
        return <></>;
      }
    }
  }

  if (activeGames.length === 0) {
    const list: string[] = [];
    if (props.games.length > 0) {
      for (const game of props.games) {
        list.push(game);
      }
    }
    setActiveGames(list);
  }

  if (activeGames.length > 0 && currentGame.length === 0) {
    let found: boolean = false;
    for (let i = 0; i < activeGames.length; i++) {
      let game = activeGames[i];

      if (loadingProgress[game] === loadingFiles[game].length) {
        for (let j = 0; j < loadingFiles[game].length; j++) {
          const gameContent: string = loadingFiles[game][j];
          contents.push(gameContent);
        }

        // Common + primer juego
        if (i >= 1 && !firstImagesReady) {
          showApp2();
          setFirstImagesReady(true);
        }
      } else if (loadingProgress[game] < loadingFiles[game].length) {
        for (let j = 0; j < loadingFiles[game].length; j++) {
          const gameContent: string = loadingFiles[game][j];
          contents.push(gameContent);
        }
        found = true;
        setCurrentGame(game);
        break;
      }
    }

    setImages(contents);

    if (!found) {
      setCurrentGame('FINISHED');
    }
  }

  return (
    <div className='invisibleImages'>
      {images.map((image, index) => (
        <img src={image} alt='ii' onLoad={loaded} key={index} />
      ))}
    </div>
  );
};

export default InvisibleImages;
