import { useAppContext } from '../../common/AppContext';
import FormularioUsuarioContent, {
  IFUQuestion,
} from './FormularioUsuarioContent';
import { FormEvent, useEffect, useState } from 'react';
import { SaveResults } from '../../common/SaveResults';
import { AppReducerActionTypes } from '../../common/AppReducer';
import { usePageScreenContext } from '../../common/PageScreenContext';
import { PageScreenReducerActionTypes } from '../../common/PageScreenReducer';
import { ScreenUtils } from '../../../utils/ScreenUtils';
import { ShowModal } from '../../../utils/ShowModal';
import { IFormAnswer } from '../../common/FormInputs/IFormAnswer';

import FormQuestionText from '../../common/FormInputs/FormQuestionText';
import FormQuestionNumber from '../../common/FormInputs/FormQuestionNumber';
import FormQuestionBinary from '../../common/FormInputs/FormQuestionBinary';
import FormQuestionDrop from '../../common/FormInputs/FormQuestionDrop';
import { QuestionTypes } from '../../common/FormInputs/QuestionTypes';
import FormQuestionEmail from '../../common/FormInputs/FormQuestionEmail';
import ReactGA from 'react-ga4';

function useForceUpdate() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState(0);
  return () => setValue(value => value + 1);
}

const FU_1 = () => {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/formulario-usuario/1' });
    ReactGA.event({
      category: 'vista',
      action: 'render',
      label: '/formulario-usuario/1',
      nonInteraction: true,
    });
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state: pageState, dispatch: pageDispatch } = usePageScreenContext();
  const { state: appState, dispatch: appDispatch } = useAppContext();
  const [disableContinuar, setDisableContinuar] = useState(false);

  const game = appState.currentScreen.game as FormularioUsuarioContent;

  ScreenUtils.showBG();
  ScreenUtils.verticalScroll(true);

  const forceUpdate = useForceUpdate();
  function onChangeCallback(data: IFormAnswer, valid: boolean) {
    game.setAnswer(data, valid);
    forceUpdate();
  }

  function next(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    ScreenUtils.verticalScroll(false);

    const formData = new FormData(
      event.target as HTMLFormElement & EventTarget
    );
    const formDictionary = Object.fromEntries(formData.entries());

    game.setFormAnswers(formDictionary);

    setDisableContinuar(true);
    SaveResults(
      game.collectedData(),
      appState.bearer_token,
      () => {
        setDisableContinuar(false);
        pageDispatch({ type: PageScreenReducerActionTypes.RESET });
        appDispatch({ type: AppReducerActionTypes.NEXT });
      },
      (connectionError: boolean, code: string, message: string) => {
        ShowModal(connectionError, appDispatch, code, message);
        setDisableContinuar(false);
      }
    );
  }

  const questionComponents: JSX.Element[] = [];
  let questions: IFUQuestion[] = game.getQuestions();

  let allAnswered: boolean = true;

  for (const question of questions) {
    let qa = game.getAnswerById(question.id);
    if (!qa || qa.value.length === 0) {
      allAnswered = false;
    }

    let valid = game.getValidById(question.id);
    if (!valid) {
      allAnswered = false;
    }

    switch (question.type) {
      case QuestionTypes.binary:
        questionComponents.push(
          <FormQuestionBinary
            id={question.id}
            question={question.question}
            onChangeCallback={onChangeCallback}
            value={qa?.value || null}
            key={'fqb' + question.id}
            options={question.options}
          />
        );
        break;
      case QuestionTypes.drop:
        questionComponents.push(
          <FormQuestionDrop
            id={question.id}
            question={question.question}
            options={question.options!}
            onChangeCallback={onChangeCallback}
            value={qa?.value || null}
            key={'fqd' + question.id}
          />
        );
        break;
      case QuestionTypes.text:
        questionComponents.push(
          <FormQuestionText
            id={question.id}
            question={question.question}
            onChangeCallback={onChangeCallback}
            value={qa?.value || null}
            key={'fqt' + question.id}
          />
        );
        break;
      case QuestionTypes.number:
        questionComponents.push(
          <FormQuestionNumber
            id={question.id}
            question={question.question}
            onChangeCallback={onChangeCallback}
            value={qa?.value || null}
            key={'fqt' + question.id}
            min={question.min}
            max={question.max}
          />
        );
        break;
      case QuestionTypes.email:
        questionComponents.push(
          <FormQuestionEmail
            id={question.id}
            question={question.question}
            onChangeCallback={onChangeCallback}
            value={qa?.value || null}
            key={'fqt' + question.id}
          />
        );
        break;
    }
  }

  return (
    <form onSubmit={next} key='formulariousuario' className='flexColumnLayout'>
      <p className='textStrong textL sideMargin50'>
        Comencemos completando tus datos de contacto
      </p>

      {questionComponents.map((question, index) => {
        return question;
      })}

      <div className='bottomButtonsSingleRight logoIntro'>
        <button
          className='nextButton'
          disabled={!allAnswered || (allAnswered && disableContinuar)}
        >
          Continuar
        </button>
      </div>
    </form>
  );
};

export default FU_1;
